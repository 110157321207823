import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css'],
  providers: [UsuarioService]
})
export class UsuarioComponent implements OnInit {
  usuarios : any;
  usuarioForm : FormGroup;
  messagePost: any;
  dtOptions: any;
  roles : any;
  usuarioId : any;
  dataValues : any;
  today = new Date();
  date = this.today.getFullYear()+'-'+ (this.today.getMonth()+1)+'-'+ this.today.getDate();
  time = this.today.getHours() + ":" + this.today.getMinutes() + ":" + this.today.getSeconds();
  constructor(private _usuarioService : UsuarioService,private toastr: ToastrService,
    private titlePage: Title, private meta: Meta
    ) { 
    this.usuarioForm = new FormGroup({
      "nombre": new FormControl('',[
        Validators.required
      ]),
      "usuario": new FormControl('',[
        Validators.required
      ]),
      "contrasena": new FormControl(''),
      "rol": new FormControl('',[
        Validators.required
      ]),
    })
  }

  ngOnInit(): void {
    this.titlePage.setTitle('Listado de Usuarios');
    this.meta.updateTag({name: "title", content: "Listado de Usuarios"});
    this.getUsuarios();
    this.getRoles();

    this.dtOptions = {
      pagingType: 'full_numbers',
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      processing: true,
      dom:  'B<"clear">lfrtip',
      buttons: [
        {
            extend: 'excel',  
            exportOptions: {
              columns: [0,1,2,3]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,

        },
        {
            extend: 'print',
            exportOptions: {
              columns: [0,1,2,3]
            },
            messageTop: "<h3>Will Fitness Club</h3><br> " + "<h4>Fecha:</h4>" + "<h4>" + this.date + " " + this.time + "</h4>",
            footer: true,

        }, 
        {
            extend: 'pdf',
            exportOptions: {
              columns: [0,1,2,3]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,


        }
                            
     ]
    };
  }

  getUsuarios(){
    this._usuarioService.getUsuarios().subscribe(response=>{
      this.usuarios = response.usuarios;
      console.log(this.usuarios);
    })
  }

  storeUsuario(){
    let dataAux = this.usuarioForm.value;
    
    if(!this.usuarioId){
      this.dataValues = {
        nombre: dataAux.nombre,
        usuario: dataAux.usuario,
        contrasena: dataAux.contrasena,
        rol: dataAux.rol
      };
      this._usuarioService.storeUsuario(this.dataValues).subscribe(response => {
        if(response.status == "success"){
          this.toastr.success('Usuario registrado correctamente');
          this.usuarioForm.reset();
          this.ngOnInit();
        }
        else{
          this.toastr.error('Error registrando el usuario, intente nuevamente');
        }
      })
    }
    else{
      if(dataAux.contrasena != ""){
        this.dataValues = {
          nombre: dataAux.nombre,
          contrasena: dataAux.contrasena,
          rol: dataAux.rol,
          id : this.usuarioId
        };
      }
      else{
        this.dataValues = {
          nombre: dataAux.nombre,
          rol: dataAux.rol,
          id : this.usuarioId
        };
      }
      this._usuarioService.updateUsuario(this.dataValues).subscribe(response => {
        this.toastr.success('Registro actualizado correctamente');
        this.usuarioForm.reset();
        this.ngOnInit();
      })
    }
  }

  getRoles(){
    this._usuarioService.getRoles().subscribe(response =>{
      this.roles = response.roles;
    })
  }
  modeForm(id? : any){
    if(!id){
      this.usuarioForm.reset();
      this.usuarioId = "";
      this.usuarioForm.controls['usuario'].enable();
    }
    else{
      var user = this.usuarios.filter((x: { id: any; }) => x.id === id);
      user = user[0];
      console.log(user[0]);
      this.usuarioForm.controls['nombre'].setValue(user.nombre);
      this.usuarioForm.controls['usuario'].setValue(user.usuario);
      this.usuarioForm.controls['usuario'].disable();
      this.usuarioForm.controls['rol'].setValue(user.rol)
      this.usuarioId = user.id;
    }
  }
  
  deleteUsuario(id : any){
    if(confirm("Estas seguro de borrar este usuario")){
      this._usuarioService.deleteUsuarios(id).subscribe(response =>{
        if(response.status == "success"){
          this.toastr.success('Usuario eliminado correctamente');
          this.usuarioForm.reset();
          this.ngOnInit();
        }
        else{
          this.toastr.error('Error eliminando el usuario intente nuevamente');
        }
      })
    }
  }
}
