import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ChartType } from 'angular-google-charts';
import { ReporteService } from 'src/app/services/reporte.service';

@Component({
  selector: 'app-resumenreporte',
  templateUrl: './resumenreporte.component.html',
  styleUrls: ['./resumenreporte.component.css'],
  providers: [ReporteService],
})
export class ResumenreporteComponent implements OnInit {
  clientesNuevos: any = 0;
  hoyVentas: any = 0;
  hoyPagos: any = 0;
  hoyPagosVisitantes: any = 0;
  clientesActivos: any;
  clientesInactivos: any;
  productosStock: any;
  dtOptions : any;
  chartData : any = '';
  columnChart : any = '';
  data : any;
  constructor(private _reporteService : ReporteService,  public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.nuevosClientes();
    this.ventasHoy();
    this.pagosHoy();
    this.pagosHoyVisitantes();
    this.membresiasEstado();
    this.productosSinStock();
    this.resumReport();

    this.dtOptions = {
      processing: true,
      dom:  'Bfrtip',
      buttons: [
        {
            extend: 'excel',  
        },
        {
            extend: 'print',
        }, 
        {
            extend: 'pdf',
        }
                            
     ]
    };

  }



  resumReport(){
    this._reporteService.getResumReport().subscribe(response =>{
      console.log(response);
      if(!response.clientes){
        response.clientes = 0;
      }

      this.columnChart  = {

        type: ChartType.ColumnChart,
    
        data: [
    
          ["Nuevas membresias", response.clientes],
      
          ["Venta Productos", response.ventas],
      
          ["Pago membresias", response.pagos],
        
          ["Pago visitantes", response.pagosHoy],
    
    
     ],
     
     width: 800,
     height: 300,
    
    
    };

    this.data = {
      "nuevasMembresias": response.clientes,
      
      "ventaProductos":response.ventas,
      
      "pagoMembresias": response.pagos,
  
      "pagoVisitantes": response.pagosHoy
  };

    });
  }
  nuevosClientes(){
    this._reporteService.getNuevosClientes().subscribe(response =>{
      this.clientesNuevos = response.clientes;
    })
  }
  ventasHoy(){
    this._reporteService.getVentasHoy().subscribe(response =>{
      this.hoyVentas = response.ventas;
    })
  }
  pagosHoy(){
    this._reporteService.getpagosHoy().subscribe(response =>{
      this.hoyPagos = response.pagos;
    })
  }
  pagosHoyVisitantes(){
    this._reporteService.getpagosHoyVisitantes().subscribe(response =>{
      this.hoyPagosVisitantes = response.pagos;
    })
  }
  membresiasEstado(){
    this._reporteService.getMembresiasStatus().subscribe(response =>{
      this.chartData = {

        type: ChartType.PieChart,
    
        data: [
    
        ["Miembros activos", response.activas],
    
        ["Miembros inactivos", response.vencidas],
    
     ],
     width: 800,
    
    };
      this.clientesActivos = response.activas;
      this.clientesInactivos = response.vencidas;
    })
  }
  productosSinStock(){
    this._reporteService.getProductosSinStock().subscribe(response =>{
      this.productosStock = response.productos;
    })
  }

}
