<div class="modal fade" id="modal-default2">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"></h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="saunaForm">
                <div class="row">
                    <div class="col-sm-12">
                        <!-- select -->
                        <div class="form-group"> 
                          <label>Clientes</label>
                          <select type="text" class="form-control" [ngClass]="{'is-invalid':saunaForm.controls['cliente'].touched && saunaForm.controls['cliente'].invalid}"  formControlName="cliente">
                            <option></option>
                            <option *ngFor="let cliente of clientes" value="{{cliente.USERID}}">{{cliente.name}}</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label><b>A pagar : </b>200</label>
                      </div>
                    </div>
                </div>
            </form>

        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" style="background-color: #3c8dbc !important;" data-dismiss="modal" [disabled]="!saunaForm.valid" (click)="storeReserva()">Guardar</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->