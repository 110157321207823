<div class="row">
    <div class="card col-md-12 table-responsive">
        <!-- /.card-header -->
        <div class="card-body">
    <h3 class="col-md-12">Resumen Anual Ventas</h3>
    <table class="table table-bordered table-hover " >
        <thead>
        <tr>
          <th>Mes</th>
          <th>Total</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let resumen of resumenVentas">
            <td>{{resumen.month}}</td>
            <td>{{resumen.total}}</td>
          </tr>
        </tbody>
      </table>
      <h5>Total: {{total}}</h5>
      </div>
    </div>
</div>