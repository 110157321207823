 <!-- Main content -->
 <section class="content">
    <div class="container-fluid">
      <!-- Info boxes -->
        <div class="card">
            <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="messagePost">
              {{messagePost}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="card-header">
              <h3 class="card-title">Listado de empleados</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <button class="btn btn-info" style="background-color: #3c8dbc !important;" data-toggle="modal" (click)="modeForm()" data-target="#modal-default">Agregar Empleados</button>
                <br>
                <br>
              <table id="tblempleados" datatable [dtOptions]="dtOptions" class="table table-bordered table-hover table-responsive" *ngIf="empleados"> 
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Sexo</th>
                  <th>Cedula</th>
                  <th>Cargo</th>
                  <th>Salario</th>
                  <th>Fecha inicio</th>
                  <th>Telefono</th>
                  <th>Email</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let empleado of empleados">
                    <td>{{empleado.USERID}}</td>
                    <td>{{empleado.name}}</td>
                    <td>{{empleado.lastname}}</td>
                    <td>{{empleado.Gender}}</td>
                    <td>{{empleado.cedula}}</td>
                    <td>{{empleado.cargo}}</td>
                    <td>{{empleado.salario}}</td>
                    <td>{{empleado.fecha_inicio}}</td>
                    <td>{{empleado.FPHONE}}</td>
                    <td>{{empleado.email}}</td>
                     <td class="no-print">
                      <button class="btn btn-info" style="background-color: #3c8dbc !important;"  data-toggle="modal" (click)="modeForm(empleado.USERID)" data-target="#modal-default">
                        <i class="fas fa-pencil-alt"></i>
                      </button>
                      <button class="btn btn-danger" (click)="deleteEmpleado(empleado.USERID)"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
      </div>
  </section>
  <!-- /.content -->

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"></h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="empleadoForm">
                <div class="row">
                    <div class="col-sm-12">
                        <!-- select -->
                        <div class="form-group"> 
                          <label>Nombre</label>
                          <input type="text" class="form-control" [ngClass]="{'is-invalid':empleadoForm.controls['nombre'].touched && empleadoForm.controls['nombre'].invalid}"  formControlName="nombre">
                        </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Apellido</label>
                        <input type="text" class="form-control" [ngClass]="{'is-invalid':empleadoForm.controls['apellido'].touched && empleadoForm.controls['apellido'].invalid}"  formControlName="apellido">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Genero</label>
                        <select class="form-control" [ngClass]="{'is-invalid':empleadoForm.controls['gender'].touched && empleadoForm.controls['gender'].invalid}"  formControlName="gender">
                          <option></option>  
                          <option value="M">M</option>
                          <option value="F">F</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Cedula</label>
                        <input type="text" class="form-control" [ngClass]="{'is-invalid':empleadoForm.controls['cedula'].touched && empleadoForm.controls['cedula'].invalid}"  formControlName="cedula">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Cargo</label>
                        <input type="text" class="form-control" [ngClass]="{'is-invalid':empleadoForm.controls['cargo'].touched && empleadoForm.controls['cargo'].invalid}"  formControlName="cargo">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Salario</label>
                        <input type="number" class="form-control" [ngClass]="{'is-invalid':empleadoForm.controls['salario'].touched && empleadoForm.controls['salario'].invalid}"  formControlName="salario">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Fecha Inicio</label>
                        <input type="date" class="form-control" [ngClass]="{'is-invalid':empleadoForm.controls['fecha_inicio'].touched && empleadoForm.controls['fecha_inicio'].invalid}"  formControlName="fecha_inicio">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Telefono</label>
                        <input type="text" class="form-control" [ngClass]="{'is-invalid':empleadoForm.controls['telefono'].touched && empleadoForm.controls['telefono'].invalid}"  formControlName="telefono">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Email</label>
                        <input type="text" class="form-control" [ngClass]="{'is-invalid':empleadoForm.controls['email'].touched && empleadoForm.controls['email'].invalid}"  formControlName="email">
                      </div>
                    </div>
                </div>
            </form>

        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" style="background-color: #3c8dbc !important;" data-dismiss="modal" [disabled]="!empleadoForm.valid" (click)="storeEmpleado()">Guardar</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->