import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(public _http: HttpClient) { }

  getUsuarios() : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/usuarios');
  }

  deleteUsuarios(id : any) : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/usuarios/delete/'+ id);
  }

  storeUsuario(data: { nombre: any; usuario: any; contrasena: any; rol: any; }) : Observable<any>{
    let json = JSON.stringify(data);
    let params= 'json='+json;
    console.log(params);
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this._http.post('https://willfitnessclub.com/gymapi/public/api/usuarios',params,{headers:headers});
  }
  updateUsuario(data : any) : Observable<any>{
    let json = JSON.stringify(data);
    let params= 'json='+json;
    console.log(params);
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this._http.put('https://willfitnessclub.com/gymapi/public/api/usuarios',params,{headers:headers});
  }

  login(data:any) : Observable<any>{
    let json = JSON.stringify(data);
    let params= 'json='+json;
    console.log(params);
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this._http.post('https://willfitnessclub.com/gymapi/public/api/usuarios/login',params,{headers:headers});
  }

  getRoles() : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/roles');
  }
}