import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProductoService } from 'src/app/services/producto.service';
import { ToastrService } from 'ngx-toastr';
import { Title, Meta } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.css'],
  providers: [ProductoService] 
})
export class ProductoComponent implements OnInit {
  [x: string]: any;
  messagePost : any;
  productoForm : any;
  productos : any;
  dtOptions : any;
  productoId : any;
  today = new Date();
  date = this.today.getFullYear()+'-'+ (this.today.getMonth()+1)+'-'+ this.today.getDate();
  time = this.today.getHours() + ":" + this.today.getMinutes() + ":" + this.today.getSeconds();
  constructor(private _productoService : ProductoService,
    private toastr: ToastrService,
    private titlePage: Title, private meta: Meta,
    private spinner: NgxSpinnerService,

    ) {
    this.productoForm = new FormGroup({
      "nombre": new FormControl('',[
        Validators.required
      ]),
      "marca": new FormControl(''),
      "precio_compra": new FormControl('',[
        Validators.required
      ]),
      "precio_venta": new FormControl('',[
        Validators.required
      ]),
      "stock": new FormControl('',[
        Validators.required
      ]),
      "sku": new FormControl(''),
    })
   }

  ngOnInit(): void {
    this.titlePage.setTitle('Listado de Productos');
    this.meta.updateTag({name: "title", content: "Listado de Productos"});
    this.getProductos();
    this.dtOptions = {
      pagingType: 'full_numbers',
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      processing: true,
      dom:  'B<"clear">lfrtip',
      buttons: [
        {
            extend: 'excel',  
            exportOptions: {
              columns: [0,1,2,3,4,5,6]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,

        },
        {
            extend: 'print',
            exportOptions: {
              columns: [0,1,2,3,4,5,6]
            },
            messageTop: "<h3>Will Fitness Club</h3><br> " + "<h4>Fecha:</h4>" + "<h4>" + this.date + " " + this.time + "</h4>",
            footer: true,

        }, 
        {
            extend: 'pdf',
            exportOptions: {
              columns: [0,1,2,3,4,5,6]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,
        }
      ]
    };
  }

  getProductos(){
    this.spinner.show();
    this._productoService.getProductos().subscribe(response=>{
      this.productos = response.productos;
        this.lastProdId = response.lastId + 1;
        this.spinner.hide();
    })
  }

  storeProducto(){
    let dataAux = this.productoForm.value;
    if(dataAux.sku == null){
      dataAux.sku = this.generateSku(this.lastProdId);
    }
    if(!this.productoId){
      let dataValues = {
        nombre: dataAux.nombre,
        marca: dataAux.marca,
        precio_compra: dataAux.precio_compra,
        precio_venta: dataAux.precio_venta,
        sku: dataAux.sku,
        stock: dataAux.stock
      };
      this._productoService.storeProductos(dataValues).subscribe(response => {
        if(response.status == "success"){
          this.toastr.success('Producto registrado correctamente');
          this.productoForm.reset();
          this.ngOnInit();
        }
        else{
          this.toastr.error('Error registrando producto, intente nuevamente');
        }
      })
    }
    else{
      let dataValues = {
        nombre: dataAux.nombre,
        marca: dataAux.marca,
        precio_compra: dataAux.precio_compra,
        precio_venta: dataAux.precio_venta,
        sku: dataAux.sku,
        stock: dataAux.stock,
        id: this.productoId 
      };
      this._productoService.updateProducto(dataValues).subscribe(response => {
        if(response.status == "success"){
          this.toastr.success('Producto actualizado correctamente');
          this.productoForm.reset();
          this.ngOnInit();
        }
        else{
          this.toastr.error('Error actualizando producto, intente nuevamente');
        }
      })
    }
    
  }

  modeForm(id? : any){
    if(!id){
      this.productoForm.reset();
      this.productoId ="";
    }
    else{
      var producto = this.productos.filter((x: { id: any; }) => x.id === id);
      producto = producto[0];
      console.log(producto);
      this.productoForm.controls['nombre'].setValue(producto.nombre);
      this.productoForm.controls['marca'].setValue(producto.marca);
      this.productoForm.controls['precio_compra'].setValue(producto.precio_compra);
      this.productoForm.controls['precio_venta'].setValue(producto.precio_venta);
      this.productoForm.controls['stock'].setValue(producto.stock);
      this.productoForm.controls['sku'].setValue(producto.sku);


      this.productoId = producto.id;
      console.log(this.productoId)
    }
  }

  deleteProducto(id : any){
    this._productoService.deleteProducto(id).subscribe(response =>{
      console.log(response);
      this.ngOnInit()
    })
  }

  generateSku(id : any){
    var sku;
    if(id < 10){
      sku = "000"+id;
    }
    else if(id >= 10 && id < 100){
       sku = "00"+id;
    }
    else if(id >= 100 && id < 1000){
       sku = "0"+id;
    }
    else if(id >= 1000 && id < 9000){
       sku = +id;
    }
    return sku;
  }

}
