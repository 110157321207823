 <!-- Main content -->
 <section class="content">
    <div class="container-fluid">
      <!-- Info boxes -->
        <div class="card">
            <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="messagePost">
              {{messagePost}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="card-header">
              <h3 class="card-title">Listado de Clientes</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive">
                <button class="btn btn-info" style="background-color: #3c8dbc !important;" data-toggle="modal" (click)="modeForm()" data-target="#modal-default">Agregar Clientes</button>
                <br>
                <br>
                <form [formGroup]="clientesFilter">
                  <input type="text" style="width:140px;border: 1pxsolid #ced4da;border-radius: 0.25rem;" formControlName="filter" placeholder="Buscar Clientes" class="form-control">
                </form>
                <br>
              <table id="tblempleados"  datatable [dtOptions]="dtOptions" class="table table-bordered table-hover" *ngIf="clientes">
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Telefono</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let cliente of clientes">
                    <td>{{cliente.USERID}}</td>
                    <td>{{cliente.name}}</td>
                    <td>{{cliente.lastname}}</td>
                    <td>{{cliente.FPHONE}}</td>
                     <td class="no-print">
                      <button class="btn btn-info" style="background-color: #3c8dbc !important;" *ngIf="cliente.USERID != 0" data-toggle="modal" (click)="modeForm(cliente.USERID)" data-target="#modal-default"><i class="fas fa-pencil-alt"></i></button>
                      <button class="btn btn-danger" (click)="deleteCliente(cliente.USERID)" *ngIf="cliente.activa == 0 && cliente.USERID != 0 && session.rol == 1"><i class="fas fa-trash-alt"></i></button>
                      <button class="btn btn-success"  data-toggle="modal" (click)="setCliente(cliente.USERID)"  data-target="#modal-default2" *ngIf="cliente.USERID != 0">
                        <i class="fas fa-plus"></i>
                      </button>
                      
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
      </div>
  </section>
  <!-- /.content -->

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"></h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="clienteForm">
                <div class="row">
                    <div class="col-sm-12">
                        <!-- select -->
                        <div class="form-group"> 
                          <label>Nombre</label>
                          <input type="text" class="form-control" [ngClass]="{'is-invalid':clienteForm.controls['nombre'].touched && clienteForm.controls['nombre'].invalid}"  formControlName="nombre">
                        </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Apellido</label>
                        <input type="text" class="form-control" [ngClass]="{'is-invalid':clienteForm.controls['apellido'].touched && clienteForm.controls['apellido'].invalid}"  formControlName="apellido">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Genero</label>
                        <select class="form-control" [ngClass]="{'is-invalid':clienteForm.controls['gender'].touched && clienteForm.controls['gender'].invalid}"  formControlName="gender">
                          <option></option>  
                          <option>M</option>
                          <option>F</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Telefono</label>
                        <input type="text" class="form-control" [ngClass]="{'is-invalid':clienteForm.controls['telefono'].touched && clienteForm.controls['telefono'].invalid}"  formControlName="telefono">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Email</label>
                        <input type="text" class="form-control" [ngClass]="{'is-invalid':clienteForm.controls['correo'].touched && clienteForm.controls['correo'].invalid}"  formControlName="correo">
                      </div>
                    </div>
                </div>
            </form>

        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" style="background-color: #3c8dbc !important;" data-dismiss="modal" [disabled]="!clienteForm.valid" (click)="storeCliente()">Guardar</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->

  <br><br>
  <app-membresia [idCliente]="idCliente"></app-membresia>
  <ngx-spinner></ngx-spinner>
