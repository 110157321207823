<div class="row">
    <div class="card col-md-12 table-responsive">
        <!-- /.card-header -->
        <div class="card-body">
    <h5 class="col-md-12">Clientes Activos</h5>
    <table datatable [dtOptions]="dtOptions" class="table table-bordered table-hover " *ngIf="clientesActivos">
        <thead>
        <tr>
          <th>Nombre</th>
          <th>Apellido</th>
          <th>Plan</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cliente of clientesActivos">
            <td>{{cliente.name}}</td>
            <td>{{cliente.lastname}}</td>
            <td>{{cliente.nombre}}</td>
          </tr>
        </tbody>
      </table>
      </div>
      </div>
</div>
<br>
<br>
<div class="row">
    <div class="card col-md-12">
        <!-- /.card-header -->
        <div class="card-body">
    <h5 class="col-md-12">Clientes Inactivos</h5>
    <table datatable [dtOptions]="dtOptions" class="table table-bordered table-hover " *ngIf="clientesInactivos">
        <thead>
        <tr>
          <th>ID</th>
          <th>Nombre</th>
          <th>Apellido</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cliente of clientesInactivos">
            <td>{{cliente.USERID}}</td>
            <td>{{cliente.name}}</td>
            <td>{{cliente.lastname}}</td>
          </tr>
        </tbody>
      </table>
      </div>
      </div>
</div>