import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Router} from '@angular/router';
import { ReporteService } from 'src/app/services/reporte.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers:[UsuarioService]
})
export class LoginComponent implements OnInit {
  loginForm : FormGroup;
  modo: any;
  error : any;

  constructor(
    private _userService: UsuarioService,
    private router : Router,
    private _reporteService : ReporteService,
    public sanitizer: DomSanitizer
  ) {
    localStorage.setItem('modo','admin');
    this.loginForm = new FormGroup({
      "usuario": new FormControl('',[
        Validators.required
      ]),
      "password": new FormControl('',[
        Validators.required
      ]),
    })
   }



  ngOnInit(): void {
  }

  login(){
    let dataAux = this.loginForm.value;
    let dataValues = {
      usuario: dataAux.usuario,
      contrasena: dataAux.password,
    };
    this._userService.login(dataValues).subscribe(response =>{
      if(response.usuario){
          this.router.navigateByUrl('admin/home');
          localStorage.setItem('session',JSON.stringify(response.usuario));
      }
      else{
        this.error = "Credenciales invalidas";
      }
    });
  }

}
