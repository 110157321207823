import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClienteService } from 'src/app/services/cliente.service';
import { PlanService } from 'src/app/services/plan.service';
import {formatDate} from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Title, Meta } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpClient } from '@angular/common/http';
import { debounceTime, tap } from 'rxjs/operators';




@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css'],
  providers: [ClienteService]
})
export class ClienteComponent implements OnInit {
  clientes : any;
  clientesSearch : any;
  clienteForm : FormGroup;
  messagePost: any;
  planes : any;
  idCliente : any;
  fechaActual : any;
  cliente : any;
  dtOptions : any;
  clienteId : any;
  session: any;
  today = new Date();
  date = this.today.getFullYear()+'-'+ (this.today.getMonth()+1)+'-'+ this.today.getDate();
  time = this.today.getHours() + ":" + this.today.getMinutes() + ":" + this.today.getSeconds();
  clientesFilter: FormGroup;
  spin: boolean = false;


  constructor(private _clienteService : ClienteService, private _planService : PlanService,private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private titlePage: Title, private meta: Meta,private http: HttpClient
    ) { 
    this.clienteForm = new FormGroup({
      "nombre": new FormControl('',[
        Validators.required
      ]),
      "apellido": new FormControl('',[
        Validators.required
      ]),
      "telefono": new FormControl(''),
      "correo": new FormControl(''),
      "gender": new FormControl('',[
        Validators.required
      ]),
    });
    this.clientesFilter = new FormGroup({
      "filter": new FormControl('')
    })
    
    this.clientesFilter.controls["filter"].valueChanges
    .pipe(
      tap(() => (this.spin = true)),
      debounceTime(1000) // tiempo que quieres que espere
    )
    .subscribe(v => {
      this.spin = false;
      this.searchClients();
    });
  }

  ngOnInit(): void {
    this.titlePage.setTitle('Listado de Clientes');
    this.meta.updateTag({name: "title", content: "Listado de Clientes"});
    this.session = JSON.parse(localStorage.getItem('session') || '{}');
    this.getClientes();
    let fecha = new Date();
    this.fechaActual = formatDate(fecha, 'yyyy/MM/dd', 'en');
    if( new Date(this.fechaActual) < new Date('2021/04/21')){
      console.log("subscripcion inactiva");
    }
    else{
      console.log("subscripcion activa")
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      paging: true,
      pageLength: 10,  
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      processing: true,
      searching: false,
      dom:  'B<"clear">lfrtip',
      buttons: [
        {
            extend: 'excel',  
            exportOptions: {
              columns: [0,1,2,3]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,

        },
        {
            extend: 'print',
            exportOptions: {
              columns: [0,1,2,3]
            },
            messageTop: "<h3>Will Fitness Club</h3><br> " + "<h4>Fecha:</h4>" + "<h4>" + this.date + " " + this.time + "</h4>",
            footer: true,

        }, 
        {
            extend: 'pdf',
            exportOptions: {
              columns: [0,1,2,3]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,


        }
      ]
    };
  }

  searchClients(){
    let filter = this.clientesFilter.value.filter;
    if(filter != ""){
      let expresion = new RegExp(`${filter}.*`, "i");
      console.log(expresion);
      let contactosFiltrados = this.clientesSearch.filter((cliente  : any)=> expresion.test(cliente.name+" "+cliente.lastname)
      || expresion.test(cliente.FPHONE))
      this.clientes = "";
      this._clienteService.getClienteByFilter("notfoundcontact").subscribe(response =>{
        this.clientes = contactosFiltrados;
         console.log(contactosFiltrados);
      });
    }
    else{
        this.ngOnInit();      
    }
  }
  
  getClientes(){
    this.spinner.show();

    this._clienteService.getClientes().subscribe(response=>{
      this.clientes = response.clientes.slice(0, 50);
      this.clientesSearch = response.clientes;
      this.clientes.forEach((value: any, index: any) => {
        if (value.acc_enddate === null){
          this.clientes[index].activa = 0;
        }
        else if( new Date(this.fechaActual) > new Date(value.acc_enddate)){
          this.clientes[index].activa = 0;
        }
        else{
          this.clientes[index].activa = 1;
        }
        this.spinner.hide();
    });
    
    this.clientesSearch.forEach((value: any, index: any) => {
      let expname = value.name.replace(/^\s+/g, '');
      this.clientesSearch[index].name = expname.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      
      if(value.lastname == "" || !value.lastname){
        value.lastname = "";
        let explastname = value.lastname.replace(/^\s+/g, '');
        this.clientesSearch[index].lastname = explastname.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      } 
      else{
        let explastname = value.lastname.replace(/^\s+/g, '');
        this.clientesSearch[index].lastname = explastname.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      } 

    });
      
    })
  }

  storeCliente(){
    let dataAux = this.clienteForm.value;
    if(!this.clienteId){
      let dataValues = {
        name: dataAux.nombre,
        lastname: dataAux.apellido,
        defaultdeptid: 1,//dataAux.departamento,
        fphone: dataAux.telefono,
        email: dataAux.correo,
        user_type : 1,
        gender : dataAux.gender
      };
      this._clienteService.storeCliente(dataValues).subscribe(response=> {
        this.cliente = response.cliente;
        let dataClient = {
          USERID : this.cliente.userid,
          Badgenumber : this.cliente.userid
        }
        this._clienteService.updateCliente(dataClient).subscribe(response=>{  
          console.log(response);
        })
        if(response.status == "success"){
          this.toastr.success('Cliente registrado correctamente');
          this.clienteForm.reset();
          this.ngOnInit();
        }
        else{
          this.toastr.error('Error registrando el cliente, intente nuevamente');
        }
      })
    }
    else{
      let dataValues = {
        name: dataAux.nombre,
        lastname: dataAux.apellido,
        defaultdeptid: 1,//dataAux.departamento,
        fphone: dataAux.telefono,
        email: dataAux.correo,
        user_type : 1,
        gender : dataAux.gender,
        userid : this.clienteId
      };

      this._clienteService.updateCliente(dataValues).subscribe(response =>{
        if(response.status == "success"){
          console.log(response);
          this.toastr.success('Cliente Actualizado correctamente');
          this.clienteForm.reset();
          this.ngOnInit();
        }
        else{
          this.toastr.error('Error actualizando el cliente, intente nuevamente');
        }
      })
    }
    
  }

  setCliente(id : any){
    this.idCliente = id;
    console.log(this.idCliente);
  }

  modeForm(id? : any){
    if(!id){
      this.clienteForm.reset();
      this.clienteId = "";
    }
    else{
      var cliente = this.clientes.filter((x: { USERID: any; }) => x.USERID === id);
      cliente = cliente[0];
      console.log(cliente);
      this.clienteForm.controls['nombre'].setValue(cliente.name);
      this.clienteForm.controls['apellido'].setValue(cliente.lastname);
      this.clienteForm.controls['telefono'].setValue(cliente.FPHONE);
      this.clienteForm.controls['correo'].setValue(cliente.email);
      this.clienteForm.controls['gender'].setValue(cliente.Gender);

      this.clienteId = cliente.USERID;
      console.log(this.clienteId);
    }

  }

  deleteCliente(id : any){
    this._clienteService.deleteCliente(id).subscribe(response =>{
      console.log(response);
      this.ngOnInit()
    })
  }

}
