import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PlanService } from 'src/app/services/plan.service';
import { ToastrService } from 'ngx-toastr';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css'],
  providers : [PlanService]
})
export class PlanComponent implements OnInit {
  planes : any;
  planForm : FormGroup;
  messagePost: any;
  dtOptions : any;
  planId: any;
  today = new Date();
  date = this.today.getFullYear()+'-'+ (this.today.getMonth()+1)+'-'+ this.today.getDate();
  time = this.today.getHours() + ":" + this.today.getMinutes() + ":" + this.today.getSeconds();
  constructor(private _planService : PlanService,
    private toastr: ToastrService,
    private titlePage: Title, private meta: Meta
    ) { 
    this.planForm = new FormGroup({
      "nombre": new FormControl('',[
        Validators.required
      ]),
      "duracion": new FormControl('',[
        Validators.required
      ]),
      "precio": new FormControl('',[
        Validators.required
      ])
    })
  }

  ngOnInit(): void {
    this.titlePage.setTitle('Listado de Planes');
    this.meta.updateTag({name: "title", content: "Listado de Planes "});
    this.getPlanes();
    this.dtOptions = {
      pagingType: 'full_numbers',
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      processing: true,
      dom:  'Bfrtip',
      buttons: [
        {
            extend: 'excel',  
            exportOptions: {
              columns: [0,1,2,3]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,

        },
        {
            extend: 'print',
            exportOptions: {
              columns: [0,1,2,3]
            },
            messageTop: "<h3>Will Fitness Club</h3><br> " + "<h4>Fecha:</h4>" + "<h4>" + this.date + " " + this.time + "</h4>",
            footer: true,

        }, 
        {
            extend: 'pdf',
            exportOptions: {
              columns: [0,1,2,3]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,
        }
      ]
    };
  }

  getPlanes(){
    this._planService.getPlanes().subscribe(response=>{
      this.planes = response.planes;
      console.log(this.planes);
    })
  }

  storePlan(){
    let dataAux = this.planForm.value;
    if(!this.planId){
      let dataValues = {
        nombre: dataAux.nombre,
        duracion: dataAux.duracion,
        precio: dataAux.precio,
      };
      this._planService.storePlanes(dataValues).subscribe(response => {
        if(response.status == "success"){
          this.toastr.success('Plan registrado correctamente');
          this.planForm.reset();
          this.ngOnInit();
        }
        else{
          this.toastr.error('Error registrando plan, intente nuevamente');
        }
      })
    }
    else{
      let dataValues = {
        nombre: dataAux.nombre,
        duracion: dataAux.duracion,
        precio: dataAux.precio,
        id : this.planId
      };
      this._planService.updatePlanes(dataValues).subscribe(response => {
        if(response.status == "success"){
          this.toastr.success('Plan actualizado correctamente');
          this.planForm.reset();
          this.ngOnInit();
        }
        else{
          this.toastr.error('Error actualizado plan, intente nuevamente');
        }
      })
    }
    
  }
  modeForm(id? : any){
    if(!id){
      this.planForm.reset();
      this.planId ="";
    }
    else{
      var plan = this.planes.filter((x: { id: any; }) => x.id === id);
      plan = plan[0];
      console.log(plan[0]);
      this.planForm.controls['nombre'].setValue(plan.nombre);
      this.planForm.controls['duracion'].setValue(plan.duracion);
      this.planForm.controls['precio'].setValue(plan.precio)
      this.planId = plan.id;
    }
  }

  deletePlan(id : any){
    this._planService.deletePlan(id).subscribe(response =>{
      console.log(response);
      this.ngOnInit()
    })
  }
}

