import { Component, OnInit } from '@angular/core';
import { CarritoCompraService } from 'src/app/services/carritocompra.service';
import { ProductoService } from 'src/app/services/producto.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VentaService } from 'src/app/services/venta.service';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, tap } from 'rxjs/operators';



@Component({
  selector: 'app-puntoventa',
  templateUrl: './puntoventa.component.html',
  styleUrls: ['./puntoventa.component.css'],
  providers : [ProductoService,CarritoCompraService,VentaService]
})

export class PuntoventaComponent implements OnInit {

  productos : any;
  dtOptions : any;
  messagePost : any;
  carritos : any;
  lastid : any;
  producto : any;
  productoSearch : any;
  posForm : FormGroup;
  stateForm : any = "d-none";
  labelState : any;
  totalventa : any;
  ventaStatus: any;
  session : any;
  filtrarForm : FormGroup;
  spin: boolean = false;



  constructor(private _productoService : ProductoService,private _carritoService : CarritoCompraService,
    private _ventaServoce : VentaService,
    private toastr: ToastrService
  ) { 
    this.posForm = new FormGroup({
      "cantidad": new FormControl('',[
        Validators.required
      ])
    });
   
    this.filtrarForm = new FormGroup({
      "producto": new FormControl('')
    });

    this.filtrarForm.controls["producto"].valueChanges
    .pipe(
      tap(() => (this.spin = true)),
      debounceTime(1000) // tiempo que quieres que espere
    )
    .subscribe(v => {
      this.spin = false;
      this.buscarProductos();
    });
  }

  ngOnInit(): void {
    this.getProductos();
    this.getCarritos();
    this.dtOptions = {
      pagingType: 'full_numbers',
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      processing: true,
      searching: false 
    };
    this.session = JSON.parse(localStorage.getItem('session') || '{}');

  }
  
  getProductos(){
    this._productoService.getProductos().subscribe(response=>{
      this.productos = response.productos;
      this.productoSearch = response.productos
    })
  }

  buscarProductos(){
    let filter = this.filtrarForm.value.producto;
    if(filter != ""){
      let expresion = new RegExp(`${filter}.*`, "i");
      console.log(expresion);
      let productosFiltrados = this.productoSearch.filter((producto  : any)=> expresion.test(producto.nombre)
      || expresion.test(producto.sku));

      if(productosFiltrados != "" && filter == productosFiltrados[0].sku){
        this.storeCarrito(productosFiltrados[0].id);
        this.filtrarForm.controls['producto'].reset();
      }
      
      if(productosFiltrados != "" && filter != productosFiltrados[0].sku){
        this.productos = productosFiltrados;
      }
    }
    else{
        this.ngOnInit();      
    }
  }

  getCarritos(){
    this._carritoService.lastId().subscribe(response=>{
      if(!response.lastid){
        this.lastid = 1
      }
      else{
        this.lastid = response.lastid.id + 1 ;
        
      }
      this._carritoService.getCarrito(this.lastid).subscribe(response=>{
        this.carritos = response.carrito_compras;
        this.totalventa = response.total;
        let cantErr = 0;

        this.carritos.forEach((value: any, index: any) => {
          this.posForm.controls['cantidad'].setValue(value.cantidad);
          this._productoService.getProductosById(value.producto.id).subscribe(response=>{
            this.carritos[index].prodDisponibles = response.producto.stock;
            if(value.cantidad > response.producto.stock){
              cantErr = cantErr + 1;
            }
            if(cantErr > 0){
              this.ventaStatus ="true";
            }
            else{
              this.ventaStatus ="";
  
            }
            console.log(cantErr);
            
          })
      });
      })
    })

  }

  storeCarrito(id : any){
    this._productoService.getProductosById(id).subscribe(response=>{
      
      this.producto = response.producto;
      
      if(this.producto.stock == 0){
        this.toastr.error('Este producto no esta disponible');
      }
      else{
        
        let dataValues = {
          producto : this.producto.id,
          //precio_compra : this.producto.precio_compra,
          precio : this.producto.precio_venta,
          cantidad : 1,
          subtotal : this.producto.precio_venta * 1,
          id_venta : this.lastid
        }

        this._carritoService.storeCarrito(dataValues).subscribe(response=>{
          
          this.productos = response.productos;
          
          if(response.status == "success"){
            this.toastr.success('Producto agregado al carrito de compras');
            this.ngOnInit();
          }

          else{
            this.toastr.error('error, no se pudo agregar este producto al carrito de compras');
          }

        })
      }
    })
  }

  updateCarrito(id : any,precio : any,event : any){
    
    let dataAux = this.posForm.value;
    
    let dataValues = {
      id : id,
      cantidad : event.target.value,
      subtotal : event.target.value * precio
    }

    this._carritoService.updateCarrito(dataValues).subscribe(response=>{
      this.ngOnInit()
    })
  }

  storeVenta(){
    this.ventaStatus = true;
    let dataValues = {
      id_venta : this.lastid,
      usuario : this.session.id
    }
    this._ventaServoce.storeVentas(dataValues).subscribe(response=>{
      this.messagePost = "Venta exitosa";
      this.ngOnInit();
      this._carritoService.truncate().subscribe(response=>{

      })
    })
  }
  setVentaStatus(status : any){
    this.ventaStatus = status;
  }

  truncate(){
    this._carritoService.truncate().subscribe(response=>{
      this.ngOnInit();
    })
  }

  hidden(){
    this.stateForm = "d-none";
    this.labelState = ""
  }

  show(){
    this.stateForm = "";
    this.labelState = "d-none"
    console.log(this.stateForm)
  }

}
