 <!-- Main content -->
 <section class="content">
    <div class="container-fluid">
      <!-- Info boxes -->
        <div class="card">
            <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="messagePost">
              {{messagePost}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="card-header">
              <h3 class="card-title">Listado de usuarios</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive">
                <button class="btn btn-info" style="background-color: #3c8dbc !important;" data-toggle="modal" data-target="#modal-default" (click)="modeForm()">Agregar Usuarios</button>
                <br>
                <br>
              <table id="tblusuarios" class="table table-bordered table-hover" datatable [dtOptions]="dtOptions" *ngIf="usuarios">
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Usuario</th>
                  <th>Rol</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let usuario of usuarios">
                    <td>{{usuario.id}}</td>
                    <td>{{usuario.nombre}}</td>
                    <td>{{usuario.usuario}}</td>
                    <td *ngIf="usuario.rol == 1">Administrador</td>
                    <td *ngIf="usuario.rol == 2">Cajero/a</td>
                     <td class="no-print">
                      <button class="btn btn-info" style="background-color: #3c8dbc !important;" (click)="modeForm(usuario.id)" data-toggle="modal" data-target="#modal-default">
                        <i class="fas fa-pencil-alt"></i>
                      </button>
                      <button class="btn btn-danger" (click)="deleteUsuario(usuario.id)"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
      </div>
  </section>
  <!-- /.content -->

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"></h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="usuarioForm">
                <div class="row">
                    <div class="col-sm-12">
                        <!-- select -->
                        <div class="form-group"> 
                          <label>Nombre</label>
                          <input type="text" class="form-control" [ngClass]="{'is-invalid':usuarioForm.controls['nombre'].touched && usuarioForm.controls['nombre'].invalid}"  formControlName="nombre">
                        </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Usuario</label>
                        <input type="text" class="form-control" [ngClass]="{'is-invalid':usuarioForm.controls['usuario'].touched && usuarioForm.controls['usuario'].invalid}"  formControlName="usuario">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Contrasena</label>
                        <input type="password" class="form-control" [ngClass]="{'is-invalid':usuarioForm.controls['contrasena'].touched && usuarioForm.controls['contrasena'].invalid}"  formControlName="contrasena">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Rol</label>
                        <select class="form-control" [ngClass]="{'is-invalid':usuarioForm.controls['rol'].touched && usuarioForm.controls['rol'].invalid}"  formControlName="rol">
                          <option></option>
                          <option *ngFor="let rol of roles" value="{{rol.id}}">{{rol.nombre}}</option>
                        </select>
                      </div>
                    </div>
                </div>
            </form>

        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" style="background-color: #3c8dbc !important;" data-dismiss="modal" [disabled]="!usuarioForm.valid" (click)="storeUsuario()">Guardar</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->

 