 <!-- Main content -->
 <section class="content">
    <div class="container-fluid">
      <!-- Info boxes -->
        <div class="card">
            
            <div class="card-header">
              <h3 class="card-title">Listado de pagos</h3>
            </div>
            <button class="btn btn-success"  data-toggle="modal"  data-target="#modal-default2">Agregar Pago Sauna</button>
            <!-- /.card-header -->
            <div class="card-body">
              <select *ngIf="session.rol == 1" class="form-control col-md-3" id="selectVenta" #mySelect (change)='getToday(mySelect.value)'>
                <option value="4">Todo</option>
                <option [selected]="true" value="1">Hoy</option>
                <option value="5">Hace 7 dias</option>
                <option value="2">Este mes</option>
                <option value="6">Mes pasado</option>
                <option value="3">Este año</option>
              </select>
                <br>
                <br>
              <table id="tblempleados" datatable [dtOptions]="dtOptions" class="table table-bordered table-hover table-responsive" *ngIf="pagos">
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Socio / Socia</th>
                  <th>Detalle / Concepto</th>
                  <th>Cantidad</th>
                  <th>Monto</th>
                  <th>Total</th>
                  <th>Fecha</th>
                  <th *ngIf="session.rol == 1">Acciones</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let pago of pagos">
                    <td>{{pago.id}}</td>
                    <td>{{pago.cliente.name}}{{pago.cliente.lastname}}</td>
                    <td>{{pago.plan.nombre}}</td>
                    <td>{{pago.cantidad}}</td>
                    <td>{{pago.monto}}</td>
                    <td>{{pago.monto * pago.cantidad}}</td>
                    <td>{{pago.created_at | date:'yyyy-MM-dd'}}</td>
                    <td *ngIf="session.rol == 1">                      
                      <button class="btn btn-danger"  (click)="deletePago(pago.id)"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>SUPERVISADO/RECIBIDO POR:___________________________</th>
                    <th>Total: RD$<u>{{total}}</u></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
      </div>
  </section>
  <!-- /.content -->

  <app-sauna></app-sauna>
  <ngx-spinner></ngx-spinner>
