import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class VentaService {

  constructor(public _http: HttpClient) { }

  getVentas(id : any) : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/ventas/all/'+id);
  }

  getResumenAnualVentas() : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/reportes/resumenanualventa');
  }
  getDetallesVentas() : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/detalles/ventas');
  }
  getDetallesVentasHoy(id : any) : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/detalles/ventas/hoy/'+id);
  }
  getVentasReporte(id : any) : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/ventas/reporte/'+id);
  }
  getVentasById(id : any) : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/ventas/'+id);
  }

  getDetallesVentaById(id : any) : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/ventas/detalles/'+id);
  }

  storeVentas(data : any) : Observable<any>{
    let json = JSON.stringify(data);
    let params= 'json='+json;
    console.log(params);
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this._http.post('https://willfitnessclub.com/gymapi/public/api/ventas',params,{headers:headers});
  }
  deleteVenta(id : any) : Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/ventas/delete/'+id);
  }
}