import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(private router : Router) { }
  session : any;
  ngOnInit(): void {
    this.session = JSON.parse(localStorage.getItem('session') || '{}');
    console.log(this.session.rol)
  }

  logout(){
    localStorage.removeItem('session');
    this.router.navigateByUrl('login');

  }

}
