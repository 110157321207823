<section class="content">
    <div class="container-fluid">
      <!-- Info boxes -->
        <div class="card">
            <div class="card-header">
              <h3 class="card-title">Listado de Ventas</h3>
            </div>
            <br>
            <select *ngIf="session.rol == 1" class="form-control col-md-3" id="selectVenta" #mySelect (change)='getToday(mySelect.value)'>
              <option value="4">Todo</option>
              <option [selected]="true" value="1">Hoy</option>
              <option value="5">Hace 7 dias</option>
              <option value="2">Este mes</option>
              <option value="6">Mes pasado</option>
              <option value="3">Este año</option>
            </select>
            <!-- /.card-header -->
            <div class="card-body">
              <table id="tblventas" datatable [dtOptions]="dtOptions" class="table table-bordered table-hover" *ngIf="detallesventas">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Producto</th>
                    <!--<th>Precio Compra</th>-->
                    <th>Precio Venta</th>
                    <th>Cantidad</th>
                    <th>Fecha</th>
                    <th>Subtotal</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let detalle of detallesventas">
                    <td>{{detalle.id}}</td>
                    <td>{{detalle.nombre}}</td>
                    <td>{{detalle.precio}}</td>
                    <!--<td>{{detalle.precio}}</td>-->
                    <td>{{detalle.cantidad}}</td>
                    <td>{{detalle.created_at}}</td>
                    <td>{{detalle.subtotal}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <!--<th>Total costo: {{totalventa}}</th>-->
                    <th></th>
                    <th>Total: {{totalventa}}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
      </div>
  </section>
  <!-- /.content -->
  <ngx-spinner></ngx-spinner>
