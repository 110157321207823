import { Component, OnInit } from '@angular/core';
import { PagoService } from 'src/app/services/pago.service';
import { Title, Meta } from '@angular/platform-browser';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.css'],
  providers: [PagoService]
})
export class PagoComponent implements OnInit {

  pagos : any;
  dtOptions : any;
  today = new Date();
  date = this.today.getFullYear()+'-'+ (this.today.getMonth()+1)+'-'+ this.today.getDate();
  time = this.today.getHours() + ":" + this.today.getMinutes() + ":" + this.today.getSeconds();
  session : any;
  total: any;
  constructor(private _pagoService : PagoService,
    private titlePage: Title, private meta: Meta,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
    ) { }


  ngOnInit(): void {
    this.titlePage.setTitle('Listado de Pagos');
    this.meta.updateTag({name: "title", content: "Listado de Pagos"});
    this.session = JSON.parse(localStorage.getItem('session') || '{}');
    this.getToday(1);
    this.dtOptions = {
      pagingType: 'full_numbers',
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      processing: true,
      dom:  'B<"clear">lfrtip',
      buttons: [
        {
            extend: 'excel',  
            exportOptions: {
              columns: [0,1,2,3,4,5,6]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,

        },
        {
            extend: 'print',
            exportOptions: {
              columns: [0,1,2,3,4,5,6]
            },
            orientation: 'landscape',
            messageTop: "<span class='text-center'>"+"<h3>WILL FITNESS CLUB</h3>" + "<h6>Av. Duarte esq. Independencia, Centro Comercial Don Pancholo Mall,<br>3er nivel, Gaspar Hernandez, RD</h6> " +
            "<b><h6>Tel./ WhatsApp: 829-856-766 * Instragram : @willfitnessclubdr * Facebook: Will Fitness Club DR</h6></b><br> <h4>TALONARIO DE CUADRE SERVICIOS</h4><br>"+"</span>"
            + "<h6 class='text-center'>FECHA <u>"+this.date+"</u>"+"&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"+"TURNO__________________________&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RESPONSABLE <u>"+this.session.nombre+"</u></h6>",
            footer: true,
            title: "",

        }, 
        {
            extend: 'pdf',
            exportOptions: {
              columns: [0,1,2,3,4,5]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,


        }
      ]
    };
  }

  getPagos(){
    this.spinner.show();
    this.session = JSON.parse(localStorage.getItem('session') || '{}');
    this._pagoService.getPagos(this.session.id).subscribe(response=>{
      this.pagos = response.pagos;
      this.total = response.total;
      this.spinner.hide();
      console.log(this.pagos)
    })
  }

  getToday(select : any){
    this.spinner.show();
    this.total = 0;
    this.pagos = null;
    let date = formatDate(this.date, 'yyyy-MM-dd HH:mm:ss', 'en');
    if(select == 1){
      this._pagoService.getPagos(this.session.id).subscribe(response=>{
        this.pagos = response.pagos.filter((x: { created_at: any; }) => x.created_at == date);
        this.pagos.forEach( (value : any, index : any) => {
          this.total += value.monto 
        });
        this.spinner.hide();

      });
    }
    else if(select == 2){
      let date2 = formatDate(date, 'yyyy-MM','en')
      this._pagoService.getPagos(this.session.id).subscribe(response=>{
        response.pagos.forEach( (value : any, index : any) => {
          value.created_at2 = formatDate(value.created_at, 'yyyy-MM', 'en')
        });
        this.pagos = response.pagos.filter((x: { created_at2: any; }) => x.created_at2 == date2);
        this.pagos.forEach( (value : any, index : any) => {
          this.total += value.monto 
        });
        this.spinner.hide();
      });
    }
    else if(select == 3){
      let date2 = formatDate(date, 'yyyy','en')
      this._pagoService.getPagos(this.session.id).subscribe(response=>{
        response.pagos.forEach( (value : any, index : any) => {
          value.created_at2 = formatDate(value.created_at, 'yyyy', 'en')
        });
        this.pagos = response.pagos.filter((x: { created_at2: any; }) => x.created_at2 == date2);
        response.pagos.forEach( (value : any, index : any) => {
          this.total += value.monto 
        });
        this.spinner.hide();
      });
    }
    else if(select == 4){
      this.getPagos();
      console.log(this.pagos);

    }
    else if(select == 5){
      this.spinner.show();
      let date2 = formatDate(date, 'yyyy-MM-dd','en');
      let fechaInicial2 = new Date(date2).setDate(new Date(date2).getDate() - 5);
      let fechaInicial3 = formatDate(fechaInicial2, 'yyyy-MM-dd','en')
      this._pagoService.getPagos(this.session.id).subscribe(response=>{
        response.pagos.forEach( (value : any, index : any) => {
          value.created_at2 = formatDate(value.created_at, 'yyyy-MM-dd', 'en')
        });
        this.pagos = response.pagos.filter((x: { created_at2: any; }) => x.created_at2 >= fechaInicial3);
        this.pagos.forEach( (value : any, index : any) => {
          this.total += value.monto 
        });
        this.spinner.hide();
      });
    }
    else if(select == 6){
      let date2 = formatDate(date, 'yyyy-MM','en')
      let fechaInicial2 = new Date(date2).setDate(new Date(date2).getDate() - 1);
      let fechaInicial3 = formatDate(fechaInicial2, 'yyyy-MM','en')
      this._pagoService.getPagos(this.session.id).subscribe(response=>{
        response.pagos.forEach( (value : any, index : any) => {
          value.created_at2 = formatDate(value.created_at, 'yyyy-MM', 'en')
        });
        this.pagos = response.pagos.filter((x: { created_at2: any; }) => x.created_at2 == fechaInicial3);
        this.total = 0;
        this.pagos.forEach( (value : any, index : any) => {
          this.total += value.monto 
        });
        this.spinner.hide();
      });
    }
  }

  deletePago(id : any){
    if(confirm("Estas seguro de borrar este pago?")){
      this._pagoService.deletePagos(id).subscribe(response =>{
        if(response.status == "success"){
          this.toastr.success('Pago eliminado correctamente');
          this.pagos = null;
          this.ngOnInit();
        }
        else{
          this.toastr.error('Error eliminando el pago, intente nuevamente');
        }
      })
    }
  }

}
