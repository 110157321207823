<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <!-- Info boxes -->
      <div class="card">
          <div class="card-header">
            <h3 class="card-title">Listado de membresias</h3>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <button class="btn btn-info" style="background-color: #3c8dbc !important;" (click)="modeForm(0)"  data-toggle="modal"  data-target="#modal-default3">Agregar Pago Visitante</button>
            <br>
            <br>
            <form [formGroup]="membresiasFilter">
              <input type="text" style="width:140px;border: 1pxsolid #ced4da;border-radius: 0.25rem;" formControlName="filter" placeholder="Buscar Membresias>" class="form-control"> 
            </form>
            <br><br>
            <table id="tblempleados" datatable [dtOptions]="dtOptions" class="table table-bordered table-hover" *ngIf="membresias">
              <thead>
              <tr>
                <th>ID</th>
                <th>Cliente</th>
                <th>Plan</th>
                <th>Fecha Inicio</th>
                <th>Fecha Fin</th>
              </tr>
              </thead>
              <tbody>
                <tr *ngFor="let membresia of membresias">
                  <td>{{membresia.id}}</td>
                  <td>{{membresia.cliente.name}} {{membresia.cliente.lastname}}</td>
                  <td>{{membresia.plan.nombre}}</td>
                  <td>{{membresia.fecha_inicio}}</td>
                  <td>{{membresia.fecha_fin}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- /.card-body -->
        </div>
        <!-- /.card -->
    </div>
</section>
<!-- /.content -->

<div class="modal fade" id="modal-default2">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"></h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="membresiaForm">
                <div class="row">
                    <div class="col-sm-12">
                        <!-- select -->
                        <div class="form-group"> 
                          <label>Planes</label>
                          <select type="text" class="form-control" [ngClass]="{'is-invalid':membresiaForm.controls['plan'].touched && membresiaForm.controls['plan'].invalid}"  formControlName="plan">
                            <option></option>
                            <option *ngFor="let plan of planesGym" value="{{plan.id}}">{{plan.nombre}}</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Cantidad</label>
                        <input type="text" class="form-control" [ngClass]="{'is-invalid':membresiaForm.controls['cantidad'].touched && membresiaForm.controls['cantidad'].invalid}" (keyup)="setPago()" formControlName="cantidad">
                      </div>
                      <div class="form-group">
                        <label><b>A pagar : </b>{{pago}}</label>
                      </div>
                    </div>
                </div>
            </form>

        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" style="background-color: #3c8dbc !important;" data-dismiss="modal" [disabled]="!membresiaForm.valid" (click)="storeMembresia()">Guardar</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->

  <div class="modal fade" id="modal-default3">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"></h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="membresiaForm">
                <div class="row">
                    <div class="col-sm-12">
                        <!-- select -->
                        <div class="form-group"> 
                          <label>Plan</label>
                          <span class="form-control">Visitante</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Cantidad</label>
                        <input type="text" class="form-control" [ngClass]="{'is-invalid':membresiaForm.controls['cantidad'].touched && membresiaForm.controls['cantidad'].invalid}" (keyup)="setPago()" formControlName="cantidad">
                      </div>
                      <div class="form-group">
                        <label><b>A pagar : </b>{{pago}}</label>
                      </div>
                    </div>
                </div>
            </form>

        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" style="background-color: #3c8dbc !important;" data-dismiss="modal"  (click)="storePago()">Guardar</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->