import { Component, OnInit } from '@angular/core';
import { ReporteService } from 'src/app/services/reporte.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-reportecliente',
  templateUrl: './reportecliente.component.html',
  styleUrls: ['./reportecliente.component.css'],
  providers: [ReporteService]
})
export class ReporteclienteComponent implements OnInit {
  clientesActivos: any;
  clientesInactivos: any;
  dtOptions : any;
  today = new Date();
  date = this.today.getFullYear()+'-'+ (this.today.getMonth()+1)+'-'+ this.today.getDate();
  time = this.today.getHours() + ":" + this.today.getMinutes() + ":" + this.today.getSeconds();
  constructor(private _reporteService : ReporteService,
    private titlePage: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.titlePage.setTitle('Listado de Clientes Activos');
    this.meta.updateTag({name: "title", content: "Listado de Clientes Activos"});
    this.getClientes();
    this.dtOptions = {
      pagingType: 'full_numbers',
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      processing: true,
      dom:  'B<"clear">lfrtip',
      buttons: [
        {
            extend: 'excel',  
            exportOptions: {
              columns: [0,1,2]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,

        },
        {
            extend: 'print',
            exportOptions: {
              columns: [0,1,2]
            },
            messageTop: "<h3>Will Fitness Club</h3><br> " + "<h4>Fecha:</h4>" + "<h4>" + this.date + " " + this.time + "</h4>",
            footer: true,

        }, 
        {
            extend: 'pdf',
            exportOptions: {
              columns: [0,1,2]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,


        }
      ]
    };
  }

  getClientes(){
    this._reporteService.getMembresiasStatus().subscribe(response=>{
      this.clientesActivos = response.clientesActivos;
      console.log(this.clientesActivos);
      this.clientesInactivos = response.clientesInactivos;
    })
  }

}
