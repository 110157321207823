import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClienteComponent } from './components/cliente/cliente.component';
import { EmpleadoComponent } from './components/empleado/empleado.component';
import { LoginComponent } from './components/login/login.component';
import { MembresiaComponent } from './components/membresia/membresia.component';
import { PlanComponent } from './components/plan/plan.component';
import { ProductoComponent } from './components/producto/producto.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { AdminlayoutComponent } from './components/adminlayout/adminlayout.component';
import {AuthGuard} from './guards/auth.guard';
import {LoginGuard} from './guards/login.guard';
import { PubliclayoutComponent } from './components/publiclayout/publiclayout.component';
import { PuntoventaComponent } from './components/puntoventa/puntoventa.component';
import { VentaComponent } from './components/venta/venta.component';
import { PagoComponent } from './components/pago/pago.component';
import { ResumenreporteComponent } from './components/resumenreporte/resumenreporte.component';
import { ReporteclienteComponent } from './components/reportecliente/reportecliente.component';
import { DetalleventaComponent } from './components/detalleventa/detalleventa.component';
import { ResumenAnualVentasComponent } from './components/resumen-anual-ventas/resumen-anual-ventas.component';
import { ResumenAnualPagosComponent } from './components/resumen-anual-pagos/resumen-anual-pagos.component';

const routes: Routes = [
  {
    path:'admin',
    component: AdminlayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'usuarios', component: UsuarioComponent, pathMatch : 'full'},
      { path: 'empleados', component: EmpleadoComponent, pathMatch : 'full'},
      { path: 'clientes', component: ClienteComponent, pathMatch : 'full'},
      { path: 'productos', component: ProductoComponent,  pathMatch : 'full'},
      { path: 'planes', component: PlanComponent, pathMatch : 'full'},
      { path: 'membresias', component: MembresiaComponent, pathMatch : 'full'},
      { path: 'pos', component: PuntoventaComponent, pathMatch : 'full'},
      { path: 'ventas', component: VentaComponent, pathMatch : 'full'},
      { path: 'pagos', component: PagoComponent, pathMatch : 'full'},
      { path: 'home', component: ResumenreporteComponent, pathMatch : 'full'},
      { path: 'reporteclientes', component: ReporteclienteComponent, pathMatch : 'full'},
      { path: 'reporteanualventas', component: ResumenAnualVentasComponent, pathMatch : 'full'},
      { path: 'reporteanualpagos', component: ResumenAnualPagosComponent, pathMatch : 'full'},
      { path: 'detallesventas', component: DetalleventaComponent, pathMatch : 'full'},




    ]
  },

  {
    path:'',
    component: PubliclayoutComponent,
    canActivate: [LoginGuard],
    children: [
      { path: 'login', component: LoginComponent, pathMatch : 'full' },
    ]
  },
  




];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
