<head>
      <!--     Fonts and icons     -->
      <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" rel="stylesheet" />
      <!-- Nucleo Icons -->
      <link rel="stylesheet" [href]="sanitizer.bypassSecurityTrustResourceUrl('../../../assets/css/soft-ui-dashboard.css?v=1.0.6')">
    </head>
    
      <main class="main-content  mt-0">
        <section>
          <div class="page-header min-vh-75">
            <div class="container">
              <div class="row">
                <div class="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto card shadow mt-6">
                  <div class="card card-plain">
                    <div class="card-header pb-0 text-left bg-transparent">
                      <h3 class="font-weight-bolder text-info text-gradient">Will Fitness GYM </h3>
                      <p class="mb-0">Digite su usuario y contraseña</p>
                      <h4 style="color: red;">{{error}}</h4>
                    </div>
                    <div class="card-body">
                      <form [formGroup]="loginForm" name="login">
                        <label>Usuario</label>
                        <div class="mb-1">
                          <input type="text" [ngClass]="{'is-invalid':loginForm.controls['usuario'].touched && loginForm.controls['usuario'].invalid}"  formControlName="usuario" class="form-control" placeholder="Digite su usuario">
                        </div>
                        <label>Contraseña</label>
                        <div class="mb-1">
                          <input type="password" id="password" [ngClass]="{'is-invalid':loginForm.controls['password'].touched && loginForm.controls['password'].invalid}"  formControlName="password"  class="form-control" placeholder="Digite su contraseña">
                        </div>
                        <div class="text-center">
                          <button type="button" [disabled]="!loginForm.valid" (click)="login()" class="btn bg-gradient-info w-100 mt-2 mb-0">Log In</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-5 text-center">
                  <img src="https://cdn-icons-png.flaticon.com/512/2964/2964514.png" width="80%">
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
 