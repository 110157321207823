import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router} from '@angular/router';


@Component({
  selector: 'app-adminlayout',
  templateUrl: './adminlayout.component.html',
  styleUrls: ['./adminlayout.component.css']
})
export class AdminlayoutComponent implements OnInit {
  menuState = "";

  constructor(private router : Router,public sanitizer: DomSanitizer) { }
  session : any;

  ngOnInit(): void {
    this.session = JSON.parse(localStorage.getItem('session') || '{}');

  }

  toggleMenu(){
    if(this.menuState == ""){
      this.menuState = "g-sidenav-pinned";
    }
    else{
      this.menuState = "";
    }
  }

  logout(){
    localStorage.removeItem('session');
    this.router.navigateByUrl('login');

  }
}
