import { Component, OnInit } from '@angular/core';
import { VentaService } from 'src/app/services/venta.service';
import { Title, Meta } from '@angular/platform-browser';
import { element } from 'protractor';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";



@Component({
  selector: 'app-venta',
  templateUrl: './venta.component.html',
  styleUrls: ['./venta.component.css'],
  providers: [VentaService]
})
export class VentaComponent implements OnInit {

  ventas : any;
  dtOptions : any;
  detalleventas: any;
  today = new Date();
  date = this.today.getFullYear()+'-'+ (this.today.getMonth()+1)+'-'+ this.today.getDate();
  time = this.today.getHours() + ":" + this.today.getMinutes() + ":" + this.today.getSeconds();
  total: any;
  visible = "";
  hidden = "d-none";
  session : any;

  constructor(private _ventaService : VentaService,
    private titlePage: Title, private meta: Meta,
    private spinner: NgxSpinnerService
    ) { }

  ngOnInit(): void {
    this.session = JSON.parse(localStorage.getItem('session') || '{}');
    this.titlePage.setTitle('Reporte de Ventas');
    this.meta.updateTag({name: "title", content: "Reporte de Ventas"});
    this.getToday(1);
    this.dtOptions = {
      pagingType: 'full_numbers',
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      processing: false,
      dom:  'B<"clear">lfrtip',
      buttons: [
        {
            extend: 'excel',  
            exportOptions: {
              columns: [0,1,2]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,

        },
        {
            extend: 'print',
            exportOptions: {
              columns: [0,1,2]
            },
            messageTop: "<h3>Will Fitness Club</h3><br> " + "<h4>Fecha:</h4>" + "<h4>" + this.date + " " + this.time + "</h4>",
            footer: true,

        }, 
        {
            extend: 'pdf',
            exportOptions: {
              columns: [0,1,2]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,


        }
                            
     ]
    };
  }

  getVentas(){
    this.spinner.show();
    this._ventaService.getVentas(this.session.id).subscribe(response=>{
      console.log(response);
      this.ventas = response.ventas;
      this.total = response.total;
      this.spinner.hide();

    })
  }

  getDetallesVenta(id:any){
    this._ventaService.getDetallesVentaById(id).subscribe(response=>{
      console.log(response);
      this.detalleventas = response.detalles;
    })
  }

  getVentasReporte(id : any){
    this._ventaService.getVentasReporte(4).subscribe(response=>{
      this.ventas = response.ventas;
    })
    if(id == 4){
      this._ventaService.getVentas(this.session.id).subscribe(response=>{
        this.ventas = response.ventas;
        this.visible = "";
        this.hidden = "d-none";   
      })
    }
    this._ventaService.getVentasReporte(id).subscribe(response=>{
      this.total = response.total;
      this.visible = "d-none";
      this.hidden = "";     
      this.ventas = response.ventas;
    })
  }

  deleteVenta(id:any){
    this._ventaService.deleteVenta(id).subscribe(response=>{
      console.log(response);
      this.ngOnInit();
    })
  }

  getToday(select : any){
    this.total = 0;
    this.ventas = null;
    let date = formatDate(this.date, 'yyyy-MM-dd HH:mm:ss', 'en');
    this.spinner.show();
    if(select == 1){
      this._ventaService.getVentas(this.session.id).subscribe(response=>{
        this.ventas = response.ventas.filter((x: { created_at: any; }) => x.created_at == date);
        this.ventas.forEach( (value : any, index : any) => {
          this.total += value.total 
        });
        this.spinner.hide();
      });
    }
    else if(select == 2){
      let date2 = formatDate(date, 'yyyy-MM','en')
      this._ventaService.getVentas(this.session.id).subscribe(response=>{
        response.ventas.forEach( (value : any, index : any) => {
          value.created_at2 = formatDate(value.created_at, 'yyyy-MM', 'en')
        });
        this.ventas = response.ventas.filter((x: { created_at2: any; }) => x.created_at2 == date2);
        this.ventas.forEach( (value : any, index : any) => {
          this.total += value.total
        });
        this.spinner.hide();
      });
    }
    else if(select == 3){
      let date2 = formatDate(date, 'yyyy','en')
      this._ventaService.getVentas(this.session.id).subscribe(response=>{
        response.ventas.forEach( (value : any, index : any) => {
          value.created_at2 = formatDate(value.created_at, 'yyyy', 'en')
        });
        this.ventas = response.ventas.filter((x: { created_at2: any; }) => x.created_at2 == date2);
        this.ventas.forEach( (value : any, index : any) => {
          this.total += value.total 
        });
        this.spinner.hide();
      });
    }
    else if(select == 4){
      this.getVentas();
    }
    else if(select == 5){
      this.spinner.show();
      let date2 = formatDate(date, 'yyyy-MM-dd','en');
      let fechaInicial2 = new Date(date2).setDate(new Date(date2).getDate() - 5);
      let fechaInicial3 = formatDate(fechaInicial2, 'yyyy-MM-dd','en')
      this._ventaService.getVentas(this.session.id).subscribe(response=>{
        response.ventas.forEach( (value : any, index : any) => {
          value.created_at2 = formatDate(value.created_at, 'yyyy-MM-dd', 'en')
        });
        console.log(fechaInicial3)
        this.ventas = response.ventas.filter((x: { created_at2: any; }) => x.created_at2 >= fechaInicial3);
        this.ventas.forEach( (value : any, index : any) => {
          this.total += value.total 
        });
        this.spinner.hide();
      });
    }
    else if(select == 6){
      let date2 = formatDate(date, 'yyyy-MM','en');
      let fechaInicial2 = new Date(date2).setDate(new Date(date2).getDate() - 1);
      let fechaInicial3 = formatDate(fechaInicial2, 'yyyy-MM','en');
      this._ventaService.getVentas(this.session.id).subscribe(response=>{
        response.ventas.forEach( (value : any, index : any) => {
          value.created_at2 = formatDate(value.created_at, 'yyyy-MM', 'en')
        });
        this.ventas = response.ventas.filter((x: { created_at2: any; }) => x.created_at2 == fechaInicial3);
        this.ventas.forEach( (value : any, index : any) => {
          this.total += value.total
        });
        this.spinner.hide();
      });
    }
  }
}
