<section class="content">
    <div class="container-fluid">
      <h2>Punto de venta productos</h2>
      <div class="row">
        <div class="card col-md-6">
            <div class="card-header">
              <h5 class="card-title">Buscar productos</h5>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
              <form [formGroup]="filtrarForm">
                <input type="text" class="form-control"  autofocus formControlName="producto" placeholder="Busque el producto aqui">
              </form> 
              <br>
              <div class="table-responsive">
                <table id="tblusuarios" datatable [dtOptions]="dtOptions" class="table table-bordered table-hover" *ngIf="productos"> 
                  <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Marca</th>
                    <th>Precio venta</th>
                    <th>Sku</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let producto of productos" (click)="storeCarrito(producto.id)">
                      <td>{{producto.nombre}}</td>
                      <td>{{producto.marca}}</td>
                      <td>{{producto.precio_venta}}</td>
                      <td>{{producto.sku}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        <!-- Info boxes -->
        <div class="card col-md-6">
            <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="messagePost">
                {{messagePost}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="card-header">
              <h5 class="card-title">Productos a vender</h5>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
              <table id="tblusuarios" class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th>Subtotal</th>
                </tr>
                </thead>
                <tbody [formGroup]="posForm">
                  <tr *ngFor="let carrito of carritos">
                    <td>{{carrito.producto.nombre}}</td>
                    <td class="col-md-3"><div (click)="show()"><label class="{{labelState}}">{{carrito.cantidad}}<br><span *ngIf="carrito.cantidad > carrito.prodDisponibles" style="color:red;font-size: 10px;">Solo tenemos {{carrito.prodDisponibles}} producto disponible</span></label></div><input type="number" class="{{stateForm}}" value="{{carrito.cantidad}}"  (mouseout)="hidden()"  (change)="updateCarrito(carrito.id,carrito.precio,$event)"></td>
                    <td>{{carrito.subtotal}}</td>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Total:{{totalventa}}</th> 
                  </tr>
                </tbody>
                <br>
                <button class="btn btn-info" (click)="storeVenta()" [disabled]="ventaStatus">Vender</button><br><br>
                <button class="btn btn-danger" (click)="truncate()">Cancelar</button>

              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
      </div>
  </section>
  <!-- /.content -->