import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EmpleadoService {

  constructor(public _http: HttpClient) { }

  getEmpleados() : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/empleados');
  }

  storeEmpleado(data: any) : Observable<any>{
    let json = JSON.stringify(data);
    let params= 'json='+json;
    console.log(params);
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this._http.post('https://willfitnessclub.com/gymapi/public/api/empleados',params,{headers:headers});
  }

  updateEmpleado(data : any) : Observable<any>{
    let json = JSON.stringify(data);
    let params= 'json='+json;
    console.log(params);
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this._http.put('https://willfitnessclub.com/gymapi/public/api/empleados',params,{headers:headers});
  }

  deleteEmpleado(id : any) : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/empleados/delete/'+ id);
  }
}