import { Component, OnInit } from '@angular/core';
import { ClienteService } from 'src/app/services/cliente.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PagoService } from 'src/app/services/pago.service';
import { ToastrService } from 'ngx-toastr';
import { PagoComponent } from '../pago/pago.component';



@Component({
  selector: 'app-sauna',
  templateUrl: './sauna.component.html',
  styleUrls: ['./sauna.component.css'],
  providers: [ClienteService,PagoService,PagoComponent]
})
export class SaunaComponent implements OnInit {

  clientes : any;
  saunaForm: FormGroup;
  session: any;
  constructor(private _clienteService : ClienteService,private _pagoService : PagoService,private toastr: ToastrService,
    private _pagoComponent : PagoComponent) { 
    this.saunaForm = new FormGroup({
      "cliente": new FormControl('')
    })
  }

  ngOnInit(): void {
    this.getClientes();
    this.session = JSON.parse(localStorage.getItem('session') || '{}');

  }

  getClientes(){
    this._clienteService.getClientes().subscribe(response=>{
      this.clientes = response.clientes;
    })
  }

  storeReserva(){
    let dataAux = this.saunaForm.value;
      let dataValues = {
        plan: 6,
        id_cliente: dataAux.cliente,
        cantidad: 1,//dataAux.departamento,
        monto: 200,
        usuario: this.session.id
      };
      this._pagoService.storePagos(dataValues).subscribe(response=>{
        if(response.status == "success"){
          this.toastr.success('Pago registrado correctamente');
          this.saunaForm.reset();
          this._pagoComponent.ngOnInit();
        }
        else{
          this.toastr.error('Error registrando el pago, intente nuevamente');
        }
      })  
  }
}
