import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ReporteService {

  constructor(public _http: HttpClient) { }

  getNuevosClientes() : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/reportes/nuevosclientes');
  }
  getVentasHoy() : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/reportes/ventashoy');
  }
  getpagosHoy() : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/reportes/pagoshoy');
  }
  getpagosHoyVisitantes() : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/reportes/pagoshoyvisitantes');
  }
  getMembresiasStatus() : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/reportes/membresiasestado');
  }
  getProductosSinStock() : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/reportes/productossinstock');
  }
  getResumReport() : Observable<any>{
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this._http.get('https://willfitnessclub.com/gymapi/public/api/reportes/resumreport');
  }
}