import { Component, OnInit } from '@angular/core';
import { VentaService } from 'src/app/services/venta.service';
import { Title, Meta } from '@angular/platform-browser';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";



@Component({
  selector: 'app-detalleventa',
  templateUrl: './detalleventa.component.html',
  styleUrls: ['./detalleventa.component.css'],
  providers:[VentaService]
})
export class DetalleventaComponent implements OnInit {

  detallesventas : any;
  dtOptions : any;
  today = new Date();
  session : any;
  totalventa : any;
  date = this.today.getFullYear()+'-'+ (this.today.getMonth()+1)+'-'+ this.today.getDate();
  time = this.today.getHours() + ":" + this.today.getMinutes() + ":" + this.today.getSeconds();
  constructor(private _ventaService : VentaService,
    private titlePage: Title, private meta: Meta,
    private spinner: NgxSpinnerService
    ) { }

  ngOnInit(): void {
    this.session = JSON.parse(localStorage.getItem('session') || '{}');
    this.titlePage.setTitle('Informe de ventas');
    this.meta.updateTag({name: "title", content: "Informe de ventas"});
    this.getToday(1);
    this.dtOptions = {
      pagingType: 'full_numbers',
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      dom:  'B<"clear">lfrtip',
      buttons: [
        {
            extend: 'excel',  
            exportOptions: {
              columns: [0,1,2,3,4,5]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,

        },
        {
            extend: 'print',
            exportOptions: {
              columns: [0,1,2,3,4,5]
            },
            messageTop: "<h3>Will Fitness Club</h3><br> " + "<h5>DON PANCHOLO MALL, TERCER NIVEL, GASPAR HERNANDEZ</h5><br> " + "<h3>829-856-7676</h3><br> " + "<h4>Fecha:</h4>" + "<h6>" + this.date + " " + this.time + "</h6>"
            + "<h6>Cajero(a):</h6>" + "<h6>"+this.session.nombre+"</h6>",
            footer: true,

        }, 
        {
            extend: 'pdf',
            exportOptions: {
              columns: [0,1,2,3,4]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,


        }
      ]
    }
  }

  getDetallesVenta(){
    this.spinner.show();
    this._ventaService.getDetallesVentasHoy(this.session.id).subscribe(response=>{
      console.log(response);
      this.detallesventas = response.ventas;
      this.totalventa = response.total;
      this.spinner.hide();
    })
  }

  getToday(select : any){
    this.totalventa = 0;
    this.detallesventas = null;
    this.spinner.show();
    let date = formatDate(this.date, 'yyyy-MM-dd', 'en');
    if(select == 1){
      this._ventaService.getDetallesVentasHoy(this.session.id).subscribe(response=>{
        this.detallesventas = response.ventas.filter((x: { created_at: any; }) => x.created_at == date);
        this.detallesventas.forEach( (value : any, index : any) => {
          this.totalventa += value.subtotal 
        });
        this.spinner.hide();

      });
    }
    else if(select == 2){
      this.spinner.show();
      let date2 = formatDate(date, 'yyyy-MM','en')
      this._ventaService.getDetallesVentasHoy(this.session.id).subscribe(response=>{
        response.ventas.forEach( (value : any, index : any) => {
          value.created_at2 = formatDate(value.created_at, 'yyyy-MM', 'en')
        });
        this.detallesventas = response.ventas.filter((x: { created_at2: any; }) => x.created_at2 == date2);
        this.detallesventas.forEach( (value : any, index : any) => {
          this.totalventa += value.subtotal
          this.spinner.hide();
        });
      });
    }
    else if(select == 3){
      this.spinner.show();
      let date2 = formatDate(date, 'yyyy','en')
      this._ventaService.getDetallesVentasHoy(this.session.id).subscribe(response=>{
        response.ventas.forEach( (value : any, index : any) => {
          value.created_at2 = formatDate(value.created_at, 'yyyy', 'en')
        });
        this.detallesventas = response.ventas.filter((x: { created_at2: any; }) => x.created_at2 == date2);
        this.detallesventas.forEach( (value : any, index : any) => {
          this.totalventa += value.subtotal 
        });
        this.spinner.hide();
      });
    }
    else if(select == 4){
      this.getDetallesVenta();
    }
    else if(select == 5){
      this.spinner.show();
      let date2 = formatDate(date, 'yyyy-MM-dd','en');
      let fechaInicial2 = new Date(date2).setDate(new Date(date2).getDate() - 5);
      let fechaInicial3 = formatDate(fechaInicial2, 'yyyy-MM-dd','en')
      this._ventaService.getDetallesVentasHoy(this.session.id).subscribe(response=>{
        response.ventas.forEach( (value : any, index : any) => {
          value.created_at2 = formatDate(value.created_at, 'yyyy-MM-dd', 'en')
        });
        console.log(fechaInicial3)
        this.detallesventas = response.ventas.filter((x: { created_at2: any; }) => x.created_at2 >= fechaInicial3);
        this.detallesventas.forEach( (value : any, index : any) => {
          this.totalventa += value.subtotal 
        });
        this.spinner.hide();
      });
    }
    else if(select == 6){
      this.spinner.show();
      let date2 = formatDate(date, 'yyyy-MM','en');
      let fechaInicial2 = new Date(date2).setDate(new Date(date2).getDate() - 1);
      let fechaInicial3 = formatDate(fechaInicial2, 'yyyy-MM','en');
      this._ventaService.getDetallesVentasHoy(this.session.id).subscribe(response=>{
        response.ventas.forEach( (value : any, index : any) => {
          value.created_at2 = formatDate(value.created_at, 'yyyy-MM', 'en')
        });
        this.totalventa = 0;
        this.detallesventas = response.ventas.filter((x: { created_at2: any; }) => x.created_at2 == fechaInicial3);
        this.detallesventas.forEach( (value : any, index : any) => {
          this.totalventa += value.subtotal
          this.spinner.hide();
        });
      });
    }
  }

}
