import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmpleadoService } from 'src/app/services/empleado.service';
import { ToastrService } from 'ngx-toastr';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-empleado',
  templateUrl: './empleado.component.html',
  styleUrls: ['./empleado.component.css']
})
export class EmpleadoComponent implements OnInit {
  empleados : any;
  empleadoForm : FormGroup;
  messagePost: any;
  dtOptions : any;
  empleadoId : any;
  today = new Date();
  date = this.today.getFullYear()+'-'+ (this.today.getMonth()+1)+'-'+ this.today.getDate();
  time = this.today.getHours() + ":" + this.today.getMinutes() + ":" + this.today.getSeconds();
  
  constructor(private _empleadoService : EmpleadoService,private toastr: ToastrService,
    private titlePage: Title, private meta: Meta
    ) { 
    this.empleadoForm = new FormGroup({
      "nombre": new FormControl('',[
        Validators.required
      ]),
      "apellido": new FormControl('',[
        Validators.required
      ]),
      "telefono": new FormControl(''),
      "email": new FormControl(''),
      "gender": new FormControl('',[
        Validators.required
      ]),
      "cedula": new FormControl('',[
        Validators.required
      ]),
      "cargo": new FormControl('',[
        Validators.required
      ]),
      "salario": new FormControl('',[
        Validators.required
      ]),
      "fecha_inicio": new FormControl('',[
        Validators.required
      ]),
    })
  }

  ngOnInit(): void {
    this.titlePage.setTitle('Listado de Empleados');
    this.meta.updateTag({name: "title", content: "Listado de Empleados"});
    this.getEmpleados();
    this.dtOptions = {
      pagingType: 'full_numbers',
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      processing: true,
      dom:  'B<"clear">lfrtip',
      buttons: [
        {
            extend: 'excel',  
            exportOptions: {
              columns: [0,1,2,3,4,5,6,7,8]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,

        },
        {
            extend: 'print',
            exportOptions: {
              columns: [0,1,2,3,4,5,6,7,8]
            },
            messageTop: "<h3>Will Fitness Club</h3><br> " + "<h4>Fecha:</h4>" + "<h4>" + this.date + " " + this.time + "</h4>",
            footer: true,

        }, 
        {
            extend: 'pdf',
            exportOptions: {
              columns: [0,1,2,3,4,5,6,7,8]
            },
            messageTop: "Will Fitness Club " + "Fecha:" + this.date + " " + this.time,
            footer: true,


        }
      ]
    };
  }

  getEmpleados(){
    this._empleadoService.getEmpleados().subscribe(response=>{
      this.empleados = response.empleados;
      console.log(this.empleados);
    })
  }

  storeEmpleado(){
    let dataAux = this.empleadoForm.value;
    if(!this.empleadoId){
      let dataValues = {
        name: dataAux.nombre,
        lastname: dataAux.apellido,
        defaultdeptid: 1,//dataAux.departamento,
        fphone: dataAux.telefono,
        email: dataAux.email,
        user_type : 2,
        gender : dataAux.gender,
        cedula : dataAux.cedula,
        cargo : dataAux.cargo,
        salario : dataAux.salario,
        fecha_inicio : dataAux.fecha_inicio
      };
      
      this._empleadoService.storeEmpleado(dataValues).subscribe(response => {
        if(response.status == "success"){
          this.toastr.success('Empleado registrado correctamente');
          this.empleadoForm.reset();
          this.ngOnInit();
        }
        else{
          this.toastr.error('Error registrando el empleado, intente nuevamente');
        }
      })
    }
    else{
      let dataValues = {
        name: dataAux.nombre,
        lastname: dataAux.apellido,
        defaultdeptid: 1,//dataAux.departamento,
        fphone: dataAux.telefono,
        email: dataAux.email,
        user_type : 2,
        gender : dataAux.gender,
        cedula : dataAux.cedula,
        cargo : dataAux.cargo,
        salario : dataAux.salario,
        USERID : this.empleadoId,
        fecha_inicio : dataAux.fecha_inicio

      };
      this._empleadoService.updateEmpleado(dataValues).subscribe(response => {
        if(response.status == "success"){
          this.toastr.success('Empleado actualizado correctamente');
          this.empleadoForm.reset();
          this.ngOnInit();
        }
        else{
          this.toastr.error('Error actualizando el empleado, intente nuevamente');
        }
      })
    }
  }

  modeForm(id? : any){
    if(!id){
      this.empleadoForm.reset();
      this.empleadoId ="";
    }
    else{
      var empleado = this.empleados.filter((x: { USERID: any; }) => x.USERID === id);
      empleado = empleado[0];
      console.log(empleado[0]);
      this.empleadoForm.controls['nombre'].setValue(empleado.name);
      this.empleadoForm.controls['apellido'].setValue(empleado.lastname);
      this.empleadoForm.controls['telefono'].setValue(empleado.FPHONE);
      this.empleadoForm.controls['email'].setValue(empleado.email);
      this.empleadoForm.controls['gender'].setValue(empleado.Gender);
      this.empleadoForm.controls['cargo'].setValue(empleado.cargo);
      this.empleadoForm.controls['salario'].setValue(empleado.salario);
      this.empleadoForm.controls['cedula'].setValue(empleado.cedula);
      this.empleadoForm.controls['fecha_inicio'].setValue(empleado.fecha_inicio);
      this.empleadoId = empleado.USERID;
    }

  }

  deleteEmpleado(id : any){
    if(confirm("Estas seguro de borrar este usuario")){
      this._empleadoService.deleteEmpleado(id).subscribe(response =>{
        if(response.status == "success"){
          this.toastr.success('Empleado eliminado correctamente');
          this.ngOnInit();
        }
        else{
          this.toastr.error('Error eliminando el empleado, intente nuevamente');
        }
      })
    }
  }
}
