 <!-- Main content -->
 <section class="content">
    <div class="container-fluid">
      <!-- Info boxes -->
        <div class="card">
            <div class="card-header">
              <h3 class="card-title">Listado de Ventas</h3>
            </div>
            <br>
            <select class="form-control col-md-3" id="selectVenta" #mySelect
            (change)='getToday(mySelect.value)'>
              <option value="4">Todo</option>
              <option [selected]="true" value="1">Hoy</option>
              <option value="5">Hace 7 dias</option>
              <option value="2">Este mes</option>
              <option value="6">Mes pasado</option>
              <option value="3">Este año</option>
            </select>
            <!-- /.card-header -->
            <div class="card-body table-responsive">
              <table id="tblventas" datatable [dtOptions]="dtOptions" class="table table-bordered table-hover" *ngIf="ventas">
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Total</th>
                  <th>Fecha</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let venta of ventas">
                    <td>{{venta.id}}</td>
                    <td>{{venta.total}}</td>
                    <td>{{venta.created_at | date:'yyyy-MM-dd'}}</td>
                     <td class="no-print">
                      <button class="btn btn-info" (click)="getDetallesVenta(venta.id)" data-toggle="modal" data-target="#modal-default"><i class="fas fa-eye"></i></button>
                      <button class="btn btn-danger" *ngIf="session.rol == 1" (click)="deleteVenta(venta.id)"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th></th>
                    <th>Total : {{total}}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
      </div>
  </section>
  <!-- /.content -->
  <ngx-spinner></ngx-spinner>

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Detalle de venta</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
                <div class="row">
                  <table class="table table-bordered table-hover">
                    <thead>
                    <tr>
                      <th>ID</th>
                      <th>Producto</th>
                      <th>Precio</th>
                      <th>Cantidad</th>
                      <th>Subtotal</th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let detalle of detalleventas">
                        <td>{{detalle.id}}</td>
                        <td>{{detalle.nombre}}</td>
                        <td>{{detalle.precio}}</td>
                        <td>{{detalle.cantidad}}</td>
                        <td>{{detalle.subtotal}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>   
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->

