 <!-- Main content -->
 <section class="content">
    <div class="container-fluid">
      <!-- Info boxes -->
        <div class="card">
            <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="messagePost">
              {{messagePost}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="card-header">
              <h3 class="card-title">Listado de planes</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive">
                <button class="btn btn-info" style="background-color: #3c8dbc !important;" (click)="modeForm()" data-toggle="modal" data-target="#modal-default">Agregar Planes</button>
                <br>
                <br>
              <table id="tblempleados" datatable [dtOptions]="dtOptions" class="table table-bordered table-hover" *ngIf="planes">
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Duracion</th>
                  <th>Precio</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let plan of planes">
                    <td>{{plan.id}}</td>
                    <td>{{plan.nombre}}</td>
                    <td>{{plan.duracion}}</td>
                    <td>{{plan.precio}}</td>
                     <td class="no-print">
                      <button class="btn btn-info" style="background-color: #3c8dbc !important;"  (click)="modeForm(plan.id)" data-toggle="modal" data-target="#modal-default"><i class="fas fa-pencil-alt"></i>
                      </button>
                      <button class="btn btn-danger" (click)="deletePlan(plan.id)"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
      </div>
  </section>
  <!-- /.content -->

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"></h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="planForm">
                <div class="row">
                    <div class="col-sm-12">
                        <!-- select -->
                        <div class="form-group"> 
                          <label>Nombre</label>
                          <input type="text" class="form-control" [ngClass]="{'is-invalid':planForm.controls['nombre'].touched && planForm.controls['nombre'].invalid}"  formControlName="nombre">
                        </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Duracion</label>
                        <input type="text" class="form-control" [ngClass]="{'is-invalid':planForm.controls['duracion'].touched && planForm.controls['duracion'].invalid}"  formControlName="duracion">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <!-- select -->
                      <div class="form-group"> 
                        <label>Precio</label>
                        <input type="text" class="form-control" [ngClass]="{'is-invalid':planForm.controls['precio'].touched && planForm.controls['precio'].invalid}"  formControlName="precio">
                      </div>
                    </div>
                </div>
            </form>

        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" style="background-color: #3c8dbc !important;" data-dismiss="modal" [disabled]="!planForm.valid" (click)="storePlan()">Guardar</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->