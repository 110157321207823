import { Component, OnInit, Input } from '@angular/core';
import { PlanService } from 'src/app/services/plan.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MembresiaService } from 'src/app/services/membresia.service';
import {formatDate} from '@angular/common';
import { ClienteService } from 'src/app/services/cliente.service';
import { ClienteComponent } from '../cliente/cliente.component';
import { ToastrService } from 'ngx-toastr';
import { PagoService } from 'src/app/services/pago.service';
import { debounceTime, tap } from 'rxjs/operators';




@Component({
  selector: 'app-membresia',
  templateUrl: './membresia.component.html',
  styleUrls: ['./membresia.component.css'],
  providers:[PlanService,MembresiaService,ClienteService,ClienteComponent,PagoService]
})
export class MembresiaComponent implements OnInit {
  membresias : any;
  membresiaForm : FormGroup;
  messagePost: any;
  planes : any;
  planesGym : any;
  @Input()
  idCliente : any;
  duracion : any;
  pago : any;
  dtOptions : any;
  session: any;
  membresiasFilter: FormGroup;
  membresiasSearch : any;
  spin = false;
  


  constructor(private _planService : PlanService, private _membresiaService : MembresiaService,
    private _clienteService : ClienteService,
    private _clienteComponent : ClienteComponent,
    private toastr: ToastrService,
    private _pagoService : PagoService
    ) { 
    this.membresiaForm = new FormGroup({
      "plan": new FormControl('',[
        Validators.required
      ]),
      "cantidad": new FormControl('',[
        Validators.required
      ]),
    });
    this.membresiasFilter = new FormGroup({
      "filter": new FormControl('')
    })
    
    this.membresiasFilter.controls["filter"].valueChanges
    .pipe(
      tap(() => (this.spin = true)),
      debounceTime(1000) // tiempo que quieres que espere
    )
    .subscribe((v: any) => {
      this.spin = false;
      this.searchMembresias();
    });
  }

  ngOnInit(): void {
    this.getMembresia();
    this.getPlanes();    
    this.setPago();
    this.session = JSON.parse(localStorage.getItem('session') || '{}');
    this.dtOptions = {
      pagingType: 'full_numbers',
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      processing: true,
      dom:  'B<"clear">lfrtip',
      searching : false,
      buttons: [
        {
            extend: 'excel',  
        },
        {
            extend: 'print',
        }, 
        {
            extend: 'pdf',
        }
                            
     ]
    };
  }
  searchMembresias(){
    let filter = this.membresiasFilter.value.filter;
    if(filter != ""){
      let expresion = new RegExp(`${filter}.*`, "i");
      let contactosFiltrados = this.membresiasSearch.filter((cliente  : any)=> expresion.test(cliente.cliente.name+" "+cliente.cliente.lastname)
      || expresion.test(cliente.cliente.FPHONE));
      console.log(contactosFiltrados)
      this.membresias = "";
      this._clienteService.getClienteByFilter("notfoundcontact").subscribe(response =>{
        this.membresias = contactosFiltrados;
         console.log(contactosFiltrados);
      });
    }
    else{
      this.membresias = "";
      this._clienteService.getClienteByFilter("notfoundcontact").subscribe(response =>{
        this.getMembresia(); 
      });
    }
  }
  getMembresia(){
    this._membresiaService.getMembresia().subscribe(response=>{
      this.membresias = response.membresias.slice(0, 50);
      this.membresiasSearch = response.membresias;
      this.membresiasSearch.forEach((value: any, index: any) => {
        let expname = value.cliente.name.replace(/^\s+/g, '');
        this.membresiasSearch[index].cliente.name = expname.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
       
        if(value.cliente.lastname == "" || !value.cliente.lastname){
          value.lastname = "";
          let explastname = value.cliente.lastname.replace(/^\s+/g, '');
          this.membresiasSearch[index].cliente.lastname = explastname.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        } 
        else{
          let explastname = value.cliente.lastname.replace(/^\s+/g, '');
          this.membresiasSearch[index].cliente.lastname = explastname.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        } 
  
      });
    })
  }

  storeMembresia(){
    let fecha = new Date();
    let fechaInicial = formatDate(fecha, 'yyyy/MM/dd', 'en');

    this._clienteService.getClienteById(this.idCliente).subscribe(response=>{
      let clienteMiembro = response.cliente;
      if(response.cliente.acc_startdate != null){
        let fechaPlan = formatDate(response.cliente.acc_enddate, 'yyyy/MM/dd', 'en');
        if( fechaPlan > fechaInicial){
          let dataAux = this.membresiaForm.value;
          this._planService.getPlanes().subscribe(response=>{
            this.planes = response.planes;
            this.duracion =  this.planes.find((x: { id: any; }) => x.id == dataAux.plan);
            this.duracion = this.duracion.duracion * dataAux.cantidad;
            
            let acc_enddate = new Date(clienteMiembro.acc_enddate);
            let fechaFinal = acc_enddate.setDate(acc_enddate.getDate() + (this.duracion));
            let fechaInicial2 = new Date(clienteMiembro.acc_enddate).setDate(new Date(clienteMiembro.acc_enddate).getDate() + 1);
            let fechaInicial3 = formatDate(fechaInicial2, 'yyyy/MM/dd', 'en');
            let fechaFinal2 = formatDate(fechaFinal, 'yyyy/MM/dd', 'en');
            
            let dataValues = {
              id_plan: dataAux.plan,
              cantidad : dataAux.cantidad,
              id_cliente: this.idCliente,
              fecha_inicio: fechaInicial3,
              fecha_fin: fechaFinal2,
              usuario: this.session.id
            };
          
          this._membresiaService.storeMembresia(dataValues).subscribe(response => {
            if(response.status == "success"){
              this.toastr.success('Membresia activada con exito');
              this.membresiaForm.reset();
              this.ngOnInit();
              window.location.reload();
            }
            else{
              this.toastr.error('Error activando membresia, intente nuevamente');
            }
          })

          let dataClient = { 
            userid : this.idCliente,
            acc_enddate: fechaFinal2,

          }
          this._clienteService.updateCliente(dataClient).subscribe(response =>{
            this._clienteComponent.getClientes();
            this.ngOnInit();
          })
        });
        }
        else{
          let dataAux = this.membresiaForm.value;
          this._planService.getPlanes().subscribe(response=>{
            this.planes = response.planes;
            this.duracion =  this.planes.find((x: { id: any; }) => x.id == dataAux.plan);
            this.duracion = this.duracion.duracion * dataAux.cantidad;
            fecha.setDate(fecha.getDate() + (this.duracion - 1));
            let fechaFinal = formatDate(fecha, 'yyyy/MM/dd', 'en');
            let dataValues = {
              id_plan: dataAux.plan,
              cantidad : dataAux.cantidad,
              id_cliente: this.idCliente,
              fecha_inicio: fechaInicial,
              fecha_fin: fechaFinal,
              usuario: this.session.id
            };

            this._membresiaService.storeMembresia(dataValues).subscribe(response => {
              if(response.status == "success"){
                this.toastr.success('Membresia activada con exito');
                this.membresiaForm.reset();
                this.ngOnInit();
                window.location.reload();
              }
              else{
                this.toastr.error('Error activando membresia, intente nuevamente');
              }
            })

            let dataClient = { 
              userid : this.idCliente,
              acc_startdate: fechaInicial,
              acc_enddate: fechaFinal,

            }
            this._clienteService.updateCliente(dataClient).subscribe(response =>{
              this._clienteComponent.getClientes();
              this.ngOnInit();
            })

          })
        }
      }
      else{
        let dataAux = this.membresiaForm.value;
        this._planService.getPlanes().subscribe(response=>{
          this.planes = response.planes;
          this.duracion =  this.planes.find((x: { id: any; }) => x.id == dataAux.plan);
          this.duracion = this.duracion.duracion * dataAux.cantidad;
          fecha.setDate(fecha.getDate() + (this.duracion - 1));
          let fechaFinal = formatDate(fecha, 'yyyy/MM/dd', 'en');
          let dataValues = {
            id_plan: dataAux.plan,
            cantidad : dataAux.cantidad,
            id_cliente: this.idCliente,
            fecha_inicio: fechaInicial,
            fecha_fin: fechaFinal,
            usuario: this.session.id
          };
        
          this._membresiaService.storeMembresia(dataValues).subscribe(response => {
            if(response.status == "success"){
              this.toastr.success('Membresia activada con exito');
              this.membresiaForm.reset();
              this.ngOnInit();
              window.location.reload();
            }
            else{
              this.toastr.error('Error activando membresia, intente nuevamente');
            }
          })

          let dataClient = { 
            userid : this.idCliente,
            acc_startdate: fechaInicial,
            acc_enddate: fechaFinal,

          }
          this._clienteService.updateCliente(dataClient).subscribe(response =>{
            this._clienteComponent.getClientes();
            this.ngOnInit();
          })

        })
      }
    });
    /*let dataAux = this.membresiaForm.value;
    this._planService.getPlanes().subscribe(response=>{
      this.planes = response.planes;
      this.duracion =  this.planes.find((x: { id: any; }) => x.id == dataAux.plan);
      this.duracion = this.duracion.duracion * dataAux.cantidad;
      fecha.setDate(fecha.getDate() + this.duracion);
      let fechaFinal = formatDate(fecha, 'yyyy/MM/dd', 'en');
      let dataValues = {
        id_plan: dataAux.plan,
        cantidad : dataAux.cantidad,
        id_cliente: this.idCliente,
        fecha_inicio: fechaInicial,
        fecha_fin: fechaFinal,
      };
    
      this._membresiaService.storeMembresia(dataValues).subscribe(response => {
        if(response.status == "success"){
          this.toastr.success('Membresia activada con exito');
          this.membresiaForm.reset();
          this.ngOnInit();
        }
        else{
          this.toastr.error('Error activando membresia, intente nuevamente');
        }
      })

      let dataClient = { 
        userid : this.idCliente,
        acc_startdate: fechaInicial,
        acc_enddate: fechaFinal,

      }
      this._clienteService.updateCliente(dataClient).subscribe(response =>{
        this._clienteComponent.getClientes();
        this.ngOnInit();
      })

    });*/
  }

  getPlanes(){
    this._planService.getPlanes().subscribe(response=>{
      this.planesGym = response.planes;
      console.log(this.planesGym);
    })
  }

  modeForm(id : any){
    this.idCliente = id;
  }

  setPago(){
    let dataAux = this.membresiaForm.value;
    if(!dataAux.plan){
      dataAux.plan = 1;
    }
    this._planService.getPlanes().subscribe(response=>{
      this.planes = response.planes;
      this.duracion =  this.planes.find((x: { id: any; }) => x.id == dataAux.plan);
      this.pago = this.duracion.precio * dataAux.cantidad;
    });
  }
  storePago(){
    let plan = this.planes.find((x: { id: any; }) => x.id == 1);
    let dataValues = {
      plan: 1,
      cantidad : 1,
      id_cliente: 0,
      monto: plan.precio,
      usuario : this.session.id
    };
    this._pagoService.storePagos(dataValues).subscribe(response=>{
      this.toastr.success('Pago visitante, registrado');
    })
  }
}
