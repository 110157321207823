import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from "angular-datatables";
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { EmpleadoComponent } from './components/empleado/empleado.component';
import { ClienteComponent } from './components/cliente/cliente.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ProductoComponent } from './components/producto/producto.component';
import { LoginComponent } from './components/login/login.component';
import { PlanComponent } from './components/plan/plan.component';
import { MembresiaComponent } from './components/membresia/membresia.component';
import { AdminlayoutComponent } from './components/adminlayout/adminlayout.component';
import { PubliclayoutComponent } from './components/publiclayout/publiclayout.component';
import { PuntoventaComponent } from './components/puntoventa/puntoventa.component';
import { VentaComponent } from './components/venta/venta.component';
import { PagoComponent } from './components/pago/pago.component';
import { ResumenreporteComponent } from './components/resumenreporte/resumenreporte.component';
import { ReporteclienteComponent } from './components/reportecliente/reportecliente.component';
import { DetalleventaComponent } from './components/detalleventa/detalleventa.component';
import { SaunaComponent } from './components/sauna/sauna.component';
import { ResumenAnualVentasComponent } from './components/resumen-anual-ventas/resumen-anual-ventas.component';
import { ResumenAnualPagosComponent } from './components/resumen-anual-pagos/resumen-anual-pagos.component';
import { GoogleChartsModule } from 'angular-google-charts';


@NgModule({
  declarations: [
    AppComponent,
    UsuarioComponent,
    EmpleadoComponent,
    ClienteComponent,
    SidebarComponent,
    ProductoComponent,
    LoginComponent,
    PlanComponent,
    MembresiaComponent,
    AdminlayoutComponent,
    PubliclayoutComponent,
    PuntoventaComponent,
    VentaComponent,
    PagoComponent,
    ResumenreporteComponent,
    ReporteclienteComponent,
    DetalleventaComponent,
    SaunaComponent,
    ResumenAnualVentasComponent,
    ResumenAnualPagosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgxSpinnerModule,
    GoogleChartsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
