import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { VentaService } from 'src/app/services/venta.service';


@Component({
  selector: 'app-resumen-anual-ventas',
  templateUrl: './resumen-anual-ventas.component.html',
  styleUrls: ['./resumen-anual-ventas.component.css']
})
export class ResumenAnualVentasComponent implements OnInit {
  resumenVentas: any;
  index = 0;
  total = 0;

  constructor(private spinner: NgxSpinnerService,private _ventaService : VentaService,) {
    
   }

  ngOnInit(): void {
    this.spinner.show();
      this._ventaService.getResumenAnualVentas().subscribe(response=>{
        this.resumenVentas = response;
        this.resumenVentas.forEach((resumen : any) =>{
          this.total += parseInt(resumen.total);
          if(resumen.month == "1"){
            this.resumenVentas[this.index].month = "Enero";
            console.log(this.resumenVentas[this.index].month);
          }
          else if(resumen.month == "2"){
            this.resumenVentas[this.index].month = "Febrero";
          }
          else if(resumen.month == "3"){
            this.resumenVentas[this.index].month = "Marzo";
          }
          else if(resumen.month == "4"){
            this.resumenVentas[this.index].month = "Abril";
          }
          else if(resumen.month == "5"){
            this.resumenVentas[this.index].month = "Mayo";
          }
          else if(resumen.month == "6"){
            this.resumenVentas[this.index].month = "Junio";
          }
          else if(resumen.month == "7"){
            this.resumenVentas[this.index].month = "Julio";
          }
          else if(resumen.month == "8"){
            this.resumenVentas[this.index].month = "Agosto";
          }
          else if(resumen.month == "9"){
            this.resumenVentas[this.index].month = "Septiembre";
          }
          else if(resumen.month == "10"){
            this.resumenVentas[this.index].month = "Octubre";
          }
          else if(resumen.month == "11"){
            this.resumenVentas[this.index].month = "Noviembre";
          }
          else if(resumen.month == "12"){
            this.resumenVentas[this.index].month = "Diciembre";
          }
          this.index++;
        });
        this.spinner.hide();
      });

      
  }

}
